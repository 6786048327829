#froala-editor-field
    .fr-toolbar .fr-command.fr-btn svg.fr-svg, .fr-popup .fr-command.fr-btn svg.fr-svg, .fr-modal .fr-command.fr-btn svg.fr-svg
        height: 18px

    .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn
        height: 30px

    .fr-separator.fr-vs
        height: 24px
        width: 1px
        margin: 8px 4px

    .fr-toolbar.fr-top
        border-bottom: 1px solid var(--border-color)
        background-color: var(--base-2)
        .fr-newline
            display: none

.ant-table-wrapper .ant-table
    .ant-table-tbody .ant-table-cell,
    .ant-table-thead .ant-table-cell
        padding: 7px 10px
        font-size: .9em

.ant-table-wrapper .ant-table .ant-table-thead
    text-transform: capitalize

.ant-table
    .ant-table-thead tr td, .ant-table-thead tr th
        background-color: var(--base-1)

    // .ant-table-body
    //     overflow-y: auto!important

.ant-tag
    text-transform: capitalize
    &.complete, &.Completed, &.Pass
        color: var(--ant-green-7)
        background: var(--ant-green-1)
        border-color: var(--ant-green-3)

    &.Publish
        color: var(--ant-cyan-7)
        background: var(--ant-cyan-1)
        border-color: var(--ant-cyan-3)

    &.awaiting.payment,  &.Enrolled
        color: var(--ant-blue-7)
        background: var(--ant-blue-1)
        border-color: var(--ant-blue-3)

    &.Private, &.Assigned
        color: var(--ant-orange-7)
        background: var(--ant-orange-1)
        border-color: var(--ant-orange-3)

    &.Failed
        color: var(--ant-red-7)
        background: var(--ant-red-1)
        border-color: var(--ant-red-3)

.fr-element .content  .fr-video::after
    display: none
