._notification
    font-size: 1em

    .notification-icon
        position: relative

    .notif-count
        position: absolute
        top: -10px
        right: -18px
        background-color: var(--primary)
        border-radius: 20px
        padding: 3px 8px
        font-size: .75em
        color: white
        border: 1px solid white

.notification-modal
    .notif-item
        margin-bottom: 6px
        padding: 10px
        border: 1px solid var(--border-color)
        border-radius: 10px
        transition: .3s
        cursor: pointer

        .group
            display: flex
            justify-content: space-between

        .is-link
            opacity: 0
            transition: .2s
            font-size: .85em
            color: var(--primary)
            height: 0
            overflow: hidden

        &:hover
            .is-link
                height: auto
                opacity: 1
                transition: .2s
