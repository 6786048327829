.course-detail-modal
    .overlay
        width: 100%
        height: 100%
        background-color: rgba(0, 0, 0, .7)
        z-index: 1
    .modal-box
        position: absolute
        top: 0
        right: 0
        width: 1100px
        min-width: 750px
        background-color: white
        height: 100vh
        overflow: auto
        z-index: 2

        .head
            position: sticky
            top: 0
            height: 150px
            z-index: 5
            .delete
                position: absolute
                right: 24px
                top: 24px
                transform: scale(1.2)

            .title
                position: absolute
                bottom: 15px
                left: 30px
                margin: 0
                font-weight: 800
                font-size: 2em
                color: white

            img, .img-overlay
                width: 100%
                height: 150px
                object-fit: cover

            .img-overlay
                position: absolute
                top: 0
                left: 0
                background-color: rgba(0, 0, 0, .7)

        .body
            display: grid
            grid-template-columns: 700px auto
            gap: 30px

            .discussion
                .comment-panel
                    display: grid
                    grid-template-columns: 1fr
                .avatar img
                    height: 30px
                    width: 30px
                    object-fit: cover
                    border-radius: 50px

            .review .review-item
                display: grid
                grid-template-columns: 1.2fr 2fr

                .ant-rate
                    transform: scale(.7)
                    transform-origin: left

                .user
                    display: flex
                    img
                        height: 30px
                        border-radius: 20px
                        object-fit: cover

                    .tip
                        font-size: .9em
                        opacity: .7

            .left-side
                padding: 30px

                .tabs
                    display: flex
                    justify-content: flex-start
                    gap: 30px

                    .tab
                        cursor: pointer

            .right-side
                position: relative
                padding: 30px
                .sticky-holder
                    position: sticky
                    top: 90px
                    z-index: 10

                .box-summary
                    border: 1px solid var(--border-color)
                    border-radius: 10px
                    overflow: hidden
                    background-color: white

                    img
                        height: 180px
                        width: 100%
                        object-fit: cover

                .summary-info
                    padding: 12px
                    .price
                        font-size: 1.3em
                        font-weight: 700

                    .button
                        width: 100%

                    .extra
                        display: grid
                        grid-template-columns: repeat(3, 1fr)
                        align-items: center
                        justify-items: center
                        text-align: center
                        div
                            display: block
                        .tip
                            opacity: .7
                            font-weight: 500

        .module-list
            .ant-collapse-content-box
                padding: 0
            .content-item
                display: grid
                grid-template-columns: 30px auto
                align-items: center
                padding: 12px 0px
                margin-left: 30px
                i
                    justify-self: center

                &:not(:first-child)
                    border-top: 1px solid var(--border-color)

        .author-box
            display: grid
            grid-template-columns: 30px auto 90px
            gap: 10px

            .avatar
                border-radius: 50%
                object-fit: cover

            .author-name
                font-weight: 500
                font-size: 1em

            .author-contact
                margin-top: 5px
                display: flex
                align-items: center
                color: var(--icon)

                span
                    padding: 6px
                    cursor: pointer

                    &:hover
                        color: var(--secondary)

.is-mobile .course-detail-modal

    .modal-box
        min-width: 100%
        width: 100vw

        .head
            backdrop-filter: blur(10px)

        .head, .head .img-overlay, .head img
            height: 175px

        .head .title
            left: 0
            padding: 1rem

        .body
            grid-template-columns: 1fr

            .left-side
                width: 100vw
                padding: 1rem

            .description
                width: 100%

        .foot
            position: sticky
            bottom: 0
            width: 100%
            padding: .75rem
            z-index: 99
            background-color: white

        .author-section
            padding: 1rem
            margin-top: 0!important
