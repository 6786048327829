@import "../../styles/color"

.public-course-card
    border-radius: 10px
    border: $border
    overflow: hidden
    cursor: pointer
    transition: all .3s

    &:hover
        box-shadow: 0 10px 20px 0px rgba(0,0,0, .1)
        border-color: transparent

        .card-head
            transition: .3s
            filter: brightness(.7)

    .card-head
        height: 180px
        img
            height: 100%
            width: 100%
            object-fit: cover

    .card-body
        height: 65px
        padding: 10px
        font-weight: 500
        border-bottom: $border

    .card-foot
        padding: 10px
        height: 65px
        display: block
        .author
            display: flex
            align-items: center
            img
                object-fit: cover
                height: 24px
                width: 24px
                border-radius: 100%

        .extra
            display: flex
            justify-content: space-between

.is-mobile .public-course-card
    box-shadow: 0 10px 20px 0px rgba(0,0,0, .1)
