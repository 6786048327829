@import "../../styles/color"

.home
    overflow: auto
    .course-list
        display: grid
        grid-template-columns: repeat(4, 1fr)
        gap: 15px

    .course-list-container
        min-height: calc( 100svh - 150px )

    @media screen and (min-width: 1600px)
        .container
            max-width: 1500px!important

        .course-list
            grid-template-columns: repeat(5, 1fr)

    @media screen and (max-width: 1215px)
        .container
            max-width: 1150px!important

        .course-list
            grid-template-columns: repeat(3, 1fr)

// MOBILE

.is-mobile .home
    .course-list
        grid-template-columns: 1fr

    .course-list-container
        min-height: calc( 100svh - 150px )

// TABLET

.is-mobile.is-tablet .home
    .course-list
        grid-template-columns: 1fr 1fr

    .course-list-container
        min-height: calc( 100svh - 150px )

.is-mobile.is-tablet.is-landscape .home
    .course-list
        grid-template-columns: 1fr 1fr 1fr
