@import "../../styles/color"

.navbar
    padding: 5px 30px
    background-color: white
    border-bottom: $border
    display: flex
    justify-content: space-between
    align-items: center
    position: sticky!important
    top: 0
    height: 50px

    .nav-brand, .nav-center, .nav-end
        display: flex
        align-items: center

    .nav-end, .nav-brand
        width: 25%

    .nav-center
        width: 50%

        .search, .input
            width: 100%
            height: 35px
            border-radius: 10px
            overflow: hidden
            transition: .2s

            &:focus
                border-color: $secondary

        .icon
            height: 35px!important
            background-color: $light-1
            border-radius: 0 10px 10px 0
            border: $border
            cursor: pointer
            pointer-events: all!important
            color: $icon!important

            &:hover
                filter: brightness(.9)

    .nav-end
        justify-content: flex-end

    .nav-user
        position: relative
        display: flex
        gap: 10px
        .extra
            display: flex

            .chat, .notif
                padding: 5px 12px
                cursor: pointer
                border-radius: 5px

                i
                    font-size: 1.1em

                &:hover
                    background-color: $transparent
                    color: $secondary
        .user
            display: flex
            justify-content: flex-end
            align-items: center
            cursor: pointer
            .avatar
                width: 30px
                height: 30px
                border-radius: 50%

            &.active ~ .user-menu
                display: block

            &.active ~ .close-popup
                display: block

        .close-popup
            position: fixed
            top: 0
            left: 0
            width: 100vw
            height: 100vh
            z-index: -99
            display: none

        .user-menu
            display: none
            padding: 10px 0
            width: 200px
            position: absolute
            top: 35px
            right: 0
            z-index: 2
            background-color: white
            border: $border
            border-radius: 10px
            box-shadow: $box-shadow

            li
                padding: 5px
                cursor: pointer
                margin: 0 10px
                border-radius: 5px

                &:hover
                    background-color: #00000010
            hr
                margin: 10px
                border: none
                border-top: $border
                height: 1px
    .logo
        height: 40px
        object-fit: cover
        cursor: pointer

    .button
        height: 35px

.navbar.nav-builder
    height: max-content
    padding: 0
    display: grid
    grid-template-columns: repeat(3, 1fr)
    .nav-start
        display: flex
        align-items: center

        img
            width: 35px
            height: 35px

    .nav-center
        width: 100%
        margin: 10px auto
        .switch
            width: 100%
            display: flex
            justify-content: center
            align-items: center

            .tab
                padding: 5px
                width: 150px
                border: $border
                text-align: center
                font-weight: 500
                cursor: pointer
                transition: .2s

                &.active
                    background-color: $secondary
                    color: white

                &.is-left
                    border-right: none
                    border-radius: 5px 0 0 5px
                &.is-right
                    border-left: none
                    border-radius: 0 5px 5px 0

    .nav-end
        width: 100%
        display: flex
        justify-content: flex-end
        cursor: pointer

        .preview
            padding: 0 24px
            font-weight: 500

        a.close
            width: 55px
            font-size: 1.2em

        a.close, .preview
            height: 100%
            display: flex
            justify-content: center
            align-items: center
            border-left: $border
            &:hover
                background-color: $secondary
                color: white

.install-prompt
    background-color: var(--primary)
    padding: .5rem
    display: flex
    justify-content: flex-end
    gap: .5rem

// MOBILE 

.is-mobile .navbar
    backdrop-filter: blur(10px)
    background-color: #ffffffce
    padding: .25rem 1rem
    display: grid
    grid-template-columns: repeat(2, 1fr)

    .mobile-search
        padding: 5px 12px
        .fa-search
            font-size: 1.15rem

    .nav-brand
        width: 100%

    .nav-end
        width: 100%

.is-mobile .navbar-search-panel
    position: fixed
    width: 100vw
    height: 100svh
    z-index: 50
    padding: 1rem
    top: 0
    left: 0
    isolation: isolate

    .overlay
        position: absolute
        width: 100vw
        height: 100svh
        background-color: #00000050
        z-index: -1
        top: 0
        left: 0
