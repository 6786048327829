.typing-animation .chat-bubble {
  background-color: #E6F8F1;
  padding: 16px 28px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}
.typing-animation .typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing-animation .typing .type-dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #69d3ae;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing-animation .typing .type-dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing-animation .typing .type-dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing-animation .typing .type-dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing-animation .typing .type-dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #3d9e7c;
  }
  28% {
    transform: translateY(-7px);
    background-color: #197e57;
  }
  44% {
    transform: translateY(0px);
    background-color: #8af1ca;
  }
}
.multi-select-action {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  padding-bottom: 1.5rem;
  background-color: var(--base-1);
  border-top: 1px solid var(--border-color);
  animation: slide-up 0.3s cubic-bezier(0.85, 0.15, 0.15, 0.85);
}
.multi-select-action .selected-count {
  background-color: var(--secondary);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}/*# sourceMappingURL=global.css.map */