.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

html, body {
  font-size: 0.95em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #24292f;
}

:root {
  --base-1: #fff;
  --base-2: #f6f8fa;
  --base-3: #ebedf0;
  --base-4: #c9d1d9;
  --base-5: #9fa5ab;
  --text-primary: #24292f;
  --text-secondary: #656d76;
  --icon: #717983;
  --border: 1px solid rgba(0, 0, 0, .1);
  --border-color: #d8dee4;
  --primary: #ff6900;
  --secondary: #00a397;
  --success: #27ca55;
  --danger: #fc5959;
  --black-10: rgba(0, 0, 0, .1);
  --black-20: rgba(0, 0, 0, .2);
  --black-30: rgba(0, 0, 0, .3);
  --black-40: rgba(0, 0, 0, .4);
  --black-50: rgba(0, 0, 0, .5);
  --black-60: rgba(0, 0, 0, .6);
  --black-70: rgba(0, 0, 0, .7);
  --black-80: rgba(0, 0, 0, .8);
  --black-90: rgba(0, 0, 0, .9);
  --black-100: rgba(0, 0, 0, 1);
  --ant-blue-1: #e6f4ff;
  --ant-blue-2: #bae0ff;
  --ant-blue-3: #91caff;
  --ant-blue-4: #69b1ff;
  --ant-blue-5: #4096ff;
  --ant-blue-6: #1677ff;
  --ant-blue-7: #0958d9;
  --ant-blue-8: #003eb3;
  --ant-blue-9: #002c8c;
  --ant-blue-10: #001d66;
  --ant-purple-1: #f9f0ff;
  --ant-purple-2: #efdbff;
  --ant-purple-3: #d3adf7;
  --ant-purple-4: #b37feb;
  --ant-purple-5: #9254de;
  --ant-purple-6: #722ed1;
  --ant-purple-7: #531dab;
  --ant-purple-8: #391085;
  --ant-purple-9: #22075e;
  --ant-purple-10: #120338;
  --ant-cyan-1: #e6fffb;
  --ant-cyan-2: #b5f5ec;
  --ant-cyan-3: #87e8de;
  --ant-cyan-4: #5cdbd3;
  --ant-cyan-5: #36cfc9;
  --ant-cyan-6: #13c2c2;
  --ant-cyan-7: #08979c;
  --ant-cyan-8: #006d75;
  --ant-cyan-9: #00474f;
  --ant-cyan-10: #002329;
  --ant-green-1: #f6ffed;
  --ant-green-2: #d9f7be;
  --ant-green-3: #b7eb8f;
  --ant-green-4: #95de64;
  --ant-green-5: #73d13d;
  --ant-green-6: #52c41a;
  --ant-green-7: #389e0d;
  --ant-green-8: #237804;
  --ant-green-9: #135200;
  --ant-green-10: #092b00;
  --ant-magenta-1: #fff0f6;
  --ant-magenta-2: #ffd6e7;
  --ant-magenta-3: #ffadd2;
  --ant-magenta-4: #ff85c0;
  --ant-magenta-5: #f759ab;
  --ant-magenta-6: #eb2f96;
  --ant-magenta-7: #c41d7f;
  --ant-magenta-8: #9e1068;
  --ant-magenta-9: #780650;
  --ant-magenta-10: #520339;
  --ant-pink-1: #fff0f6;
  --ant-pink-2: #ffd6e7;
  --ant-pink-3: #ffadd2;
  --ant-pink-4: #ff85c0;
  --ant-pink-5: #f759ab;
  --ant-pink-6: #eb2f96;
  --ant-pink-7: #c41d7f;
  --ant-pink-8: #9e1068;
  --ant-pink-9: #780650;
  --ant-pink-10: #520339;
  --ant-red-1: #fff1f0;
  --ant-red-2: #ffccc7;
  --ant-red-3: #ffa39e;
  --ant-red-4: #ff7875;
  --ant-red-5: #ff4d4f;
  --ant-red-6: #f5222d;
  --ant-red-7: #cf1322;
  --ant-red-8: #a8071a;
  --ant-red-9: #820014;
  --ant-red-10: #5c0011;
  --ant-orange-1: #fff7e6;
  --ant-orange-2: #ffe7ba;
  --ant-orange-3: #ffd591;
  --ant-orange-4: #ffc069;
  --ant-orange-5: #ffa940;
  --ant-orange-6: #fa8c16;
  --ant-orange-7: #d46b08;
  --ant-orange-8: #ad4e00;
  --ant-orange-9: #873800;
  --ant-orange-10: #612500;
  --ant-yellow-1: #feffe6;
  --ant-yellow-2: #ffffb8;
  --ant-yellow-3: #fffb8f;
  --ant-yellow-4: #fff566;
  --ant-yellow-5: #ffec3d;
  --ant-yellow-6: #fadb14;
  --ant-yellow-7: #d4b106;
  --ant-yellow-8: #ad8b00;
  --ant-yellow-9: #876800;
  --ant-yellow-10: #614700;
  --ant-volcano-1: #fff2e8;
  --ant-volcano-2: #ffd8bf;
  --ant-volcano-3: #ffbb96;
  --ant-volcano-4: #ff9c6e;
  --ant-volcano-5: #ff7a45;
  --ant-volcano-6: #fa541c;
  --ant-volcano-7: #d4380d;
  --ant-volcano-8: #ad2102;
  --ant-volcano-9: #871400;
  --ant-volcano-10: #610b00;
  --ant-geekblue-1: #f0f5ff;
  --ant-geekblue-2: #d6e4ff;
  --ant-geekblue-3: #adc6ff;
  --ant-geekblue-4: #85a5ff;
  --ant-geekblue-5: #597ef7;
  --ant-geekblue-6: #2f54eb;
  --ant-geekblue-7: #1d39c4;
  --ant-geekblue-8: #10239e;
  --ant-geekblue-9: #061178;
  --ant-geekblue-10: #030852;
  --ant-gold-1: #fffbe6;
  --ant-gold-2: #fff1b8;
  --ant-gold-3: #ffe58f;
  --ant-gold-4: #ffd666;
  --ant-gold-5: #ffc53d;
  --ant-gold-6: #faad14;
  --ant-gold-7: #d48806;
  --ant-gold-8: #ad6800;
  --ant-gold-9: #874d00;
  --ant-gold-10: #613400;
  --ant-lime-1: #fcffe6;
  --ant-lime-2: #f4ffb8;
  --ant-lime-3: #eaff8f;
  --ant-lime-4: #d3f261;
  --ant-lime-5: #bae637;
  --ant-lime-6: #a0d911;
  --ant-lime-7: #7cb305;
  --ant-lime-8: #5b8c00;
  --ant-lime-9: #3f6600;
  --ant-lime-10: #254000;
  background-color: var(--base-1);
}

:root:has(.dark-theme) {
  --base-1: #0d1117;
  --base-2: #161b22;
  --base-3: #21262d;
  --base-4: #373e47;
  --base-5: #444a52;
  --primary: #ff6900;
  --secondary: #00a397;
  --danger: #fc5959;
  --text-primary: #c9d1d9;
  --text-secondary: #7d8590;
  --icon: #ebedf0;
  --border: 1px solid #21262d;
  --border-color: var(--base-3);
  --box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
  --black-10: #00000010;
  background-color: var(--base-1);
}

#app {
  background: var(--base-1);
}
#app .content p {
  margin-bottom: 0.75rem;
}
#app .navbar {
  position: sticky;
  top: 0;
}

#meta-root {
  overflow: auto;
  background: var(--base-1);
  height: 100svh;
}

#app-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
#app-modal .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  display: block;
}
#app-modal .overlay {
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: rgba(3, 15, 20, 0.8196078431);
  top: 0;
  left: 0;
}

.modal {
  display: block;
}
.modal .overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
}
.modal .modal-box {
  min-width: 400px;
  background-color: white;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  overflow: hidden;
}
.modal .modal-header {
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d7de;
}
.modal .modal-header h3 {
  font-size: 1.2em;
}
.modal .modal-body {
  padding: 10px 24px 24px 24px;
}

.no-scroll {
  overflow: hidden !important;
}

a {
  color: inherit;
}

.option i.las {
  font-size: 1.3em;
  padding: 4px;
}
.option i.las::before {
  margin-top: 2px;
}

.non {
  display: none !important;
}

.w-100 {
  width: 100%;
}

hr {
  border-top: 1px solid #d0d7de;
  border-color: #d0d7de;
  background-color: unset;
  border-top-style: dashed;
}

.is-link {
  cursor: pointer;
}

.is-file-link {
  cursor: pointer;
  transition: 0.2s;
}
.is-file-link:hover {
  color: var(--primary);
}

.button {
  font-size: 0.9em;
  transition: 0.2s;
  border-radius: 6px;
  background-color: #fafafd;
}

.button-link {
  color: #00a397;
  cursor: pointer;
  font-size: 0.9em;
}
.button-link:hover {
  filter: brightness(0.8);
}
.button-link.is-danger {
  color: #f76979;
}

.title {
  font-weight: 700;
  color: #24292f;
}

.editorjs-block .paragraph {
  margin-bottom: 1em;
}

.text-center {
  text-align: center !important;
}

.input, .textarea {
  box-shadow: none;
  border: 1px solid #d0d7de;
  padding: 5px 10px;
  font-size: 0.9em;
  line-height: 18px;
}
.input:focus, .textarea:focus {
  border-color: #00a397;
  box-shadow: none;
}

.flex-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Dropdown-control {
  padding: 4px 30px 4px 10px !important;
}

.tag {
  border-radius: 5px;
  width: -moz-max-content;
  width: max-content;
  padding: 3px 10px;
  font-size: 1em !important;
}
.tag.Draft {
  background-color: #f6f8fa;
}
.tag.Private {
  color: #ff6900;
  background-color: #fff7f1;
}
.tag.Publish, .tag.Assigned {
  color: #053f83;
  background-color: #F3F8FF;
}
.tag.Completed {
  color: #067A72;
  background-color: #eefdfb;
}

.pagination {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #d0d7de;
  background-color: white;
  padding: 10px 24px;
}
.pagination .page-control {
  display: flex;
  align-items: center;
}
.pagination .prev, .pagination .next {
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid #d0d7de;
  height: 40px;
}
.pagination .input {
  width: 60px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #d0d7de;
  border-radius: 0;
  box-shadow: none;
}

.proper {
  text-transform: capitalize;
}

.rating-star {
  color: red;
}

.rating-star-gray {
  color: blue;
}

.field .control .tip, .field .tip {
  font-size: 13px;
  opacity: 0.7;
}
.field .control .checkbox, .field .checkbox {
  padding: 0;
}
.field .control .block-group, .field .block-group {
  display: block;
}
.field .control .flex-group, .field .flex-group {
  display: flex;
}
.field .control label, .field .control .label, .field label, .field .label {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  color: #57606a;
}

.fr-element.fr-view {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
.fr-element.fr-view h1 {
  font-size: 2.5em;
}
.fr-element.fr-view strong {
  font-weight: 700;
}
.fr-element.fr-view ul li, .fr-element.fr-view ol li {
  margin-left: 14px;
}

:where(.css-dev-only-do-not-override-ebburg).ant-table-wrapper .ant-table-column-sorter {
  transform: scale(0.7);
}

/* Antd override */
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-group-solid .ant-radio-button.ant-radio-button-checked {
  background-color: #00a397;
  border-color: #00a397;
  color: white;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.ant-radio-group-solid-inner::after {
  background-color: #00a397;
}

.ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  border-color: #00a397;
}
.ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper.ant-radio-button-wrapper-checked span.ant-radio-button.ant-radio-button-checked {
  background-color: rgba(0, 163, 152, 0.062745098);
}
.ant-radio-group.ant-radio-group-outline .ant-radio-button-wrapper.ant-radio-button-wrapper-checked span {
  color: #00a397;
}

:where(.css-dev-only-do-not-override-ebburg).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #00a397;
  border-color: #00a397;
}

.ant-radio-group-solid :where(.css-dev-only-do-not-override-diro6f).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #00a397;
  border-color: #00a397;
}

.ant-radio-group-solid :where(.css-dev-only-do-not-override-diro6f).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #00a397;
  border-color: #00a397;
}

:where(.css-dev-only-do-not-override-diro6f).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #00a397;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00a397;
  border-color: #00a397;
}

/* Change the color of the label text */
.ant-checkbox-wrapper .label {
  color: #00a397;
}

:where(.css-dev-only-do-not-override-diro6f).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-diro6f).ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #00a397;
}

:where(.css-dev-only-do-not-override-diro6f).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #00a397;
  border-color: transparent;
}

:where(.css-dev-only-do-not-override-diro6f).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #00a397;
}

:where(.css-dev-only-do-not-override-diro6f).ant-input:focus, :where(.css-dev-only-do-not-override-diro6f).ant-input-focused {
  border-color: #00a397;
  box-shadow: 0 0 0 2px rgba(5, 186, 255, 0.08);
  border-inline-end-width: 1px;
  outline: 0;
}

:where(.css-dev-only-do-not-override-diro6f).ant-input:hover {
  border-color: #00a397;
  border-inline-end-width: 1px;
}

:where(.css-dev-only-do-not-override-diro6f).ant-input-number:focus, :where(.css-dev-only-do-not-override-diro6f).ant-input-number-focused {
  border-color: #00a397;
  box-shadow: 0 0 0 2px rgba(5, 186, 255, 0.08);
  border-inline-end-width: 1px;
  outline: 0;
}

:where(.css-dev-only-do-not-override-diro6f).ant-input-number:hover {
  border-color: #00a397;
  border-inline-end-width: 1px;
}

.ant-popconfirm-description {
  max-width: 300px;
}

.ant-table-column-sorter-inner {
  transform: scale(0.8);
}

.fr-popup.fr-active {
  z-index: 9999 !important;
}

#fr-logo {
  display: none;
}

.cdx-block.embed-tool {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.cdx-block.embed-tool iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=style.css.map */