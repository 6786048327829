.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.footer-box {
  margin-top: 30px;
  background-color: #fafafa;
  padding: 24px;
  color: #57606a;
}
.footer-box .container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr !important;
  align-items: center;
}
.footer-box .end {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}
.footer-box .logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-box .logo-box svg {
  width: 30px;
  fill: #57606a;
}

.is-mobile .footer-box {
  padding: 3rem 1rem;
}
.is-mobile .footer-box .container {
  display: grid;
  grid-template-columns: 1fr !important;
  grid-template-areas: "logo" "social" "copyright";
  justify-content: center;
  gap: 1rem;
}
.is-mobile .footer-box .end {
  grid-area: social;
  justify-content: center;
}
.is-mobile .footer-box .logo-box {
  grid-area: logo;
  margin-bottom: 1.5rem;
}
.is-mobile .footer-box .logo-box svg {
  width: 50px;
}
.is-mobile .footer-box .start {
  grid-area: copyright;
  text-align: center;
}/*# sourceMappingURL=footer.css.map */