.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.setting {
  background-image: linear-gradient(to right, var(--base-2), var(--base-2), var(--base-1), var(--base-1));
  height: calc(100vh - 50px);
}
.setting .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 35px 8px 24px;
}
.setting .container .edit-avatar {
  text-align: right;
}
.setting .container .edit-avatar .ant-upload-list-item-container {
  width: 200px !important;
  height: 200px !important;
}
.setting .container .edit-avatar .ant-upload.ant-upload-select {
  width: 200px !important;
  height: 200px !important;
}
.setting .head h3 {
  font-size: 1.5em;
  font-weight: 800;
}
.setting .edit-profile, .setting .edit-account, .setting .billing-info {
  padding: 0 16px;
}
.setting .edit-profile .profile-form, .setting .edit-account .profile-form, .setting .billing-info .profile-form {
  display: grid;
  grid-template-columns: 1fr 250px;
}
.setting .edit-profile .form, .setting .edit-account .form, .setting .billing-info .form {
  padding: 24px;
  border: 1px solid #d0d7de;
  background-color: white;
  border-radius: 10px;
}
.setting .edit-profile .form .field-group, .setting .edit-account .form .field-group, .setting .billing-info .form .field-group {
  display: flex;
  gap: 10px;
}
.setting .edit-profile .form .field, .setting .edit-account .form .field, .setting .billing-info .form .field {
  width: 100%;
}
.setting .edit-profile .form .ant-input, .setting .edit-account .form .ant-input, .setting .billing-info .form .ant-input {
  width: 100%;
}
.setting .edit-profile .form .label, .setting .edit-account .form .label, .setting .billing-info .form .label {
  font-weight: 500;
  font-size: 0.9em;
}
.setting .edit-profile .form .tip, .setting .edit-account .form .tip, .setting .billing-info .form .tip {
  opacity: 0.7;
}
.setting .setting-tabs .ant-tabs-nav-list {
  padding: 10px 0;
  background-color: transparent;
  max-height: 134px;
  border: none;
  border-radius: 6px;
}
.setting .setting-tabs .tab {
  cursor: pointer;
}
.setting .setting-tabs .ant-tabs-tab {
  margin: 0 !important;
}
.setting .setting-tabs .ant-tabs-tab:hover {
  color: var(--secondary);
}
.setting .setting-tabs .ant-tabs-content-holder {
  border: none;
}

.role-upgrade-panel {
  position: relative;
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  border-radius: 10px;
  overflow: hidden;
}
.role-upgrade-panel .bg {
  width: 100%;
  background-color: #fc8601;
}
.role-upgrade-panel img {
  height: 100%;
  position: absolute;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: left;
     object-position: left;
  left: 0;
}

.setting .billing-info .billing {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.setting .billing-info .billing h6 {
  opacity: 0.8;
}
.setting .billing-info .billing p {
  font-size: 1.3em;
  font-weight: 400;
  margin-bottom: 10px;
}
.setting .billing-info .billing .balance, .setting .billing-info .billing .income, .setting .billing-info .billing .spending {
  background-color: var(--base-1);
  padding: 1rem 1.5rem;
  border-radius: 10px;
  border: 1px solid var(--border-color);
}

.setting .ant-tabs-content-holder {
  min-height: calc(100svh - 50px);
  background-color: var(--base-1);
  padding-top: 1.5rem;
}
.setting .ant-tabs-nav-wrap {
  padding-top: 1rem;
}

.is-mobile .setting .setting-tabs {
  height: calc(100svh - 50px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 50px;
  grid-template-areas: "content" "tabs";
}
.is-mobile .setting .ant-tabs-content-holder {
  min-height: unset;
  padding: 0;
}
.is-mobile .setting .ant-tabs-nav, .is-mobile .setting .ant-tabs-nav-wrap {
  margin: 0;
  padding: 0;
}
.is-mobile .setting .ant-tabs-nav-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  width: 100%;
}
.is-mobile .setting .ant-tabs-nav-list .ant-tabs-tab {
  padding: 0.5rem;
  justify-content: center;
}
.is-mobile .setting .edit-profile, .is-mobile .setting .billing-info, .is-mobile .setting .edit-account {
  height: calc(100svh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
}
.is-mobile .setting .edit-profile .profile-form {
  grid-template-columns: 1fr;
  gap: 1rem;
  grid-template-areas: "avatar" "form";
}
.is-mobile .setting .edit-profile .profile-form .edit-avatar {
  text-align: left;
  grid-area: avatar;
}
.is-mobile .setting .edit-profile .profile-form .anticon svg {
  fill: var(--icon);
}
.is-mobile .setting .billing-info .billing {
  grid-template-columns: 1fr;
  gap: 0.5rem;
}
.is-mobile .setting .billing-info .ant-pagination {
  justify-content: center;
}/*# sourceMappingURL=setting.css.map */