.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.login, .register, .reset-password {
  display: flex;
  height: 100svh;
  display: grid;
  grid-template-columns: 30% auto;
}
.login img, .register img, .reset-password img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.login .login-panel, .login .register-panel, .login .reset-password-panel, .register .login-panel, .register .register-panel, .register .reset-password-panel, .reset-password .login-panel, .reset-password .register-panel, .reset-password .reset-password-panel {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 420px;
  margin: auto;
}
.login .login-panel button, .login .register-panel button, .login .reset-password-panel button, .register .login-panel button, .register .register-panel button, .register .reset-password-panel button, .reset-password .login-panel button, .reset-password .register-panel button, .reset-password .reset-password-panel button {
  width: 100%;
}
.login .login-panel .name-field, .login .register-panel .name-field, .login .reset-password-panel .name-field, .register .login-panel .name-field, .register .register-panel .name-field, .register .reset-password-panel .name-field, .reset-password .login-panel .name-field, .reset-password .register-panel .name-field, .reset-password .reset-password-panel .name-field {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.login .login-panel .logo-box, .login .register-panel .logo-box, .login .reset-password-panel .logo-box, .register .login-panel .logo-box, .register .register-panel .logo-box, .register .reset-password-panel .logo-box, .reset-password .login-panel .logo-box, .reset-password .register-panel .logo-box, .reset-password .reset-password-panel .logo-box {
  width: 100%;
  margin-bottom: 40px;
  text-align: center;
}
.login .login-panel .logo, .login .register-panel .logo, .login .reset-password-panel .logo, .register .login-panel .logo, .register .register-panel .logo, .register .reset-password-panel .logo, .reset-password .login-panel .logo, .reset-password .register-panel .logo, .reset-password .reset-password-panel .logo {
  width: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  margin: auto;
}
.login .login-panel .login-form, .login .login-panel .register-form, .login .register-panel .login-form, .login .register-panel .register-form, .login .reset-password-panel .login-form, .login .reset-password-panel .register-form, .register .login-panel .login-form, .register .login-panel .register-form, .register .register-panel .login-form, .register .register-panel .register-form, .register .reset-password-panel .login-form, .register .reset-password-panel .register-form, .reset-password .login-panel .login-form, .reset-password .login-panel .register-form, .reset-password .register-panel .login-form, .reset-password .register-panel .register-form, .reset-password .reset-password-panel .login-form, .reset-password .reset-password-panel .register-form {
  width: 100%;
}
.login .login-panel .login-form .input, .login .login-panel .login-form .button, .login .login-panel .register-form .input, .login .login-panel .register-form .button, .login .register-panel .login-form .input, .login .register-panel .login-form .button, .login .register-panel .register-form .input, .login .register-panel .register-form .button, .login .reset-password-panel .login-form .input, .login .reset-password-panel .login-form .button, .login .reset-password-panel .register-form .input, .login .reset-password-panel .register-form .button, .register .login-panel .login-form .input, .register .login-panel .login-form .button, .register .login-panel .register-form .input, .register .login-panel .register-form .button, .register .register-panel .login-form .input, .register .register-panel .login-form .button, .register .register-panel .register-form .input, .register .register-panel .register-form .button, .register .reset-password-panel .login-form .input, .register .reset-password-panel .login-form .button, .register .reset-password-panel .register-form .input, .register .reset-password-panel .register-form .button, .reset-password .login-panel .login-form .input, .reset-password .login-panel .login-form .button, .reset-password .login-panel .register-form .input, .reset-password .login-panel .register-form .button, .reset-password .register-panel .login-form .input, .reset-password .register-panel .login-form .button, .reset-password .register-panel .register-form .input, .reset-password .register-panel .register-form .button, .reset-password .reset-password-panel .login-form .input, .reset-password .reset-password-panel .login-form .button, .reset-password .reset-password-panel .register-form .input, .reset-password .reset-password-panel .register-form .button {
  width: 100%;
  height: 40px;
}
.login .login-panel .login-form .message, .login .login-panel .register-form .message, .login .register-panel .login-form .message, .login .register-panel .register-form .message, .login .reset-password-panel .login-form .message, .login .reset-password-panel .register-form .message, .register .login-panel .login-form .message, .register .login-panel .register-form .message, .register .register-panel .login-form .message, .register .register-panel .register-form .message, .register .reset-password-panel .login-form .message, .register .reset-password-panel .register-form .message, .reset-password .login-panel .login-form .message, .reset-password .login-panel .register-form .message, .reset-password .register-panel .login-form .message, .reset-password .register-panel .register-form .message, .reset-password .reset-password-panel .login-form .message, .reset-password .reset-password-panel .register-form .message {
  background-color: rgba(247, 105, 121, 0.0901960784);
  text-align: center;
  padding: 5px;
  color: #f76979;
  border: 1px solid #d0d7de;
  border-color: #f76979;
}
.login .register-panel .logo-box, .register .register-panel .logo-box, .reset-password .register-panel .logo-box {
  margin-bottom: 20px;
}
.login .register-panel .field.password p, .register .register-panel .field.password p, .reset-password .register-panel .field.password p {
  display: none;
}
.login .register-panel .form-header .tip, .register .register-panel .form-header .tip, .reset-password .register-panel .form-header .tip {
  font-size: 0.9em;
  opacity: 0.7;
  text-align: center;
}
.login .flex-group, .register .flex-group, .reset-password .flex-group {
  display: flex;
  justify-content: space-between;
}
.login .label, .register .label, .reset-password .label {
  font-weight: 500;
}
.login .forgot-password, .register .forgot-password, .reset-password .forgot-password {
  opacity: 0.5;
  cursor: pointer;
}
.login .forgot-password:hover, .register .forgot-password:hover, .reset-password .forgot-password:hover {
  opacity: 1;
}
.login .icon, .register .icon, .reset-password .icon {
  color: #57606a !important;
}
.login p, .register p, .reset-password p {
  font-size: 1rem;
}

.forgot-password-form {
  width: 420px;
}
.forgot-password-form .title, .forgot-password-form p {
  text-align: center;
}

.registration-success {
  padding: 3em;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registration-success .group {
  text-align: center;
}
.registration-success .logo {
  width: 300px;
}
.registration-success img {
  display: block;
  margin: auto;
}
.registration-success p {
  font-size: 1.2em;
}

.field-error {
  color: var(--danger);
  font-size: 0.9em;
}

.is-mobile .login, .is-mobile .register, .is-mobile .reset-password {
  display: grid;
  grid-template-columns: 1fr;
  width: 100vw;
  padding: 2rem 1rem;
  overflow-x: auto;
}
.is-mobile .login .image-box, .is-mobile .register .image-box, .is-mobile .reset-password .image-box {
  display: none;
}
.is-mobile .login .login-panel, .is-mobile .login .register-panel, .is-mobile .login .reset-password-panel, .is-mobile .register .login-panel, .is-mobile .register .register-panel, .is-mobile .register .reset-password-panel, .is-mobile .reset-password .login-panel, .is-mobile .reset-password .register-panel, .is-mobile .reset-password .reset-password-panel {
  width: clamp(300px, 100%, 100%);
}
.is-mobile .login .login-panel .forgot-password-form, .is-mobile .login .register-panel .forgot-password-form, .is-mobile .login .reset-password-panel .forgot-password-form, .is-mobile .register .login-panel .forgot-password-form, .is-mobile .register .register-panel .forgot-password-form, .is-mobile .register .reset-password-panel .forgot-password-form, .is-mobile .reset-password .login-panel .forgot-password-form, .is-mobile .reset-password .register-panel .forgot-password-form, .is-mobile .reset-password .reset-password-panel .forgot-password-form {
  width: 100%;
}/*# sourceMappingURL=auth.css.map */