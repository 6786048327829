@import "../../styles/color"

.login, .register, .reset-password
    display: flex
    height: 100svh
    display: grid
    grid-template-columns: 30% auto

    img
        height: 100%
        object-fit: cover

    .login-panel, .register-panel, .reset-password-panel
        justify-content: center
        align-items: center
        flex-direction: column
        width: 420px
        margin: auto

        button
            width: 100%

        .name-field
            display: grid
            grid-template-columns: 1fr 1fr
            gap: 10px

        .logo-box
            width: 100%
            margin-bottom: 40px
            text-align: center

        .logo
            width: 300px
            object-fit: cover
            margin: auto

        .login-form, .register-form
            width: 100%

            .input, .button
                width: 100%
                height: 40px

            .message
                background-color: $danger-transparent
                text-align: center
                padding: 5px
                color: $danger
                border: $border
                border-color: $danger

    .register-panel
        .logo-box
            margin-bottom: 20px

        .field.password
            p
                display: none

        .form-header
            .tip
                font-size: .9em
                opacity: .7
                text-align: center

    .flex-group
        display: flex
        justify-content: space-between

    .label
        font-weight: 500
    .forgot-password
        opacity: .5
        cursor: pointer

        &:hover
            opacity: 1

    .icon
        color: $icon!important

    p
        font-size: 1rem

.forgot-password-form
    width: 420px

    .title, p
        text-align: center

.registration-success
    padding: 3em
    height: 100svh
    display: flex
    justify-content: center
    align-items: center
    .group
        text-align: center

    .logo
        width: 300px

    img
        display: block
        margin: auto

    p
        font-size: 1.2em

.field-error
    color: var(--danger)
    font-size: .9em

.is-mobile .login, .is-mobile .register, .is-mobile .reset-password
    display: grid
    grid-template-columns: 1fr
    width: 100vw
    padding: 2rem 1rem
    overflow-x: auto

    .image-box
        display: none

    .login-panel, .register-panel, .reset-password-panel
        width: clamp(300px, 100%, 100%)

        .forgot-password-form
            width: 100%
