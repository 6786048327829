.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profile {
  background-image: linear-gradient(to right, var(--base-2), var(--base-2), var(--base-1), var(--base-1));
  height: calc(100vh - 50px);
  overflow: hidden;
}
.profile .ant-tabs .ant-tabs-nav {
  padding-top: 16px;
  height: calc(100svh - 50px);
}
.profile .ant-tabs .ant-tabs-tab {
  margin-top: 5px !important;
  padding-right: 35px !important;
}
.profile .ant-tabs .ant-tabs-tabpane {
  padding: 0 !important;
}
.profile .profile-tabs {
  height: 100%;
  width: 100%;
  border-right: 1px solid #d0d7de;
}
.profile .profile-tabs .tab {
  cursor: pointer;
  width: 100%;
  padding: 6px;
  border-radius: 10px;
  margin-bottom: 3px;
}
.profile .profile-tabs .tab.active {
  background-color: rgba(255, 105, 0, 0.0588235294);
  color: #ff6900;
  font-weight: 500;
}
.profile .profile-tabs .tab:hover {
  background-color: rgba(0, 0, 0, 0.062745098);
}
.profile .profile-box {
  height: inherit;
  background-color: #fafafd;
  overflow: auto;
  margin-bottom: 30px;
}
.profile .authored-courses, .profile .authored-subjects, .profile .personal-courses, .profile .personal-subjects, .profile .overview {
  height: inherit;
  position: relative;
  opacity: 1;
  padding: 0 10px;
}
.profile .authored-courses.is-loading, .profile .authored-subjects.is-loading, .profile .personal-courses.is-loading, .profile .personal-subjects.is-loading, .profile .overview.is-loading {
  pointer-events: none;
  opacity: 0.5;
}
.profile .authored-courses .header-box, .profile .authored-subjects .header-box, .profile .personal-courses .header-box, .profile .personal-subjects .header-box, .profile .overview .header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 0 16px;
}
.profile .authored-courses .header-box .title, .profile .authored-subjects .header-box .title, .profile .personal-courses .header-box .title, .profile .personal-subjects .header-box .title, .profile .overview .header-box .title {
  font-size: 16px;
}
.profile .authored-courses .header-box .query, .profile .authored-subjects .header-box .query, .profile .personal-courses .header-box .query, .profile .personal-subjects .header-box .query, .profile .overview .header-box .query {
  display: flex;
  gap: 8px;
}
.profile .authored-courses .header-box .status .Dropdown-control, .profile .authored-subjects .header-box .status .Dropdown-control, .profile .personal-courses .header-box .status .Dropdown-control, .profile .personal-subjects .header-box .status .Dropdown-control, .profile .overview .header-box .status .Dropdown-control {
  border: 1px solid #d0d7de;
  border-radius: 5px;
  min-width: 100px;
  width: -moz-max-content;
  width: max-content;
  padding: 8px 30px 8px 10px;
  cursor: pointer;
}
.profile .authored-courses .header-box .status .Dropdown-placeholder::before, .profile .authored-subjects .header-box .status .Dropdown-placeholder::before, .profile .personal-courses .header-box .status .Dropdown-placeholder::before, .profile .personal-subjects .header-box .status .Dropdown-placeholder::before, .profile .overview .header-box .status .Dropdown-placeholder::before {
  content: "Status : ";
  opacity: 0.5;
}
.profile .authored-courses .header-box .status .Dropdown-menu, .profile .authored-subjects .header-box .status .Dropdown-menu, .profile .personal-courses .header-box .status .Dropdown-menu, .profile .personal-subjects .header-box .status .Dropdown-menu, .profile .overview .header-box .status .Dropdown-menu {
  border: 1px solid #d0d7de;
  border-radius: 5px;
  margin-top: 3px;
}
.profile .authored-courses table::-webkit-scrollbar, .profile .authored-subjects table::-webkit-scrollbar, .profile .personal-courses table::-webkit-scrollbar, .profile .personal-subjects table::-webkit-scrollbar, .profile .overview table::-webkit-scrollbar {
  width: 8px;
}
.profile .authored-courses table::-webkit-scrollbar-track, .profile .authored-subjects table::-webkit-scrollbar-track, .profile .personal-courses table::-webkit-scrollbar-track, .profile .personal-subjects table::-webkit-scrollbar-track, .profile .overview table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.068);
}
.profile .authored-courses table::-webkit-scrollbar-thumb, .profile .authored-subjects table::-webkit-scrollbar-thumb, .profile .personal-courses table::-webkit-scrollbar-thumb, .profile .personal-subjects table::-webkit-scrollbar-thumb, .profile .overview table::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.profile .authored-courses .header-box {
  display: grid;
  grid-template-columns: 1fr 1fr 120px;
  gap: 0.5rem;
}
.profile .authored-courses .header-box .query {
  justify-content: flex-end;
}

.data-body {
  display: grid;
  grid-template-columns: auto 0px;
  height: calc(100% - 54px);
}
.data-body .table-box {
  padding: 16px;
}
.data-body .detail-item-panel {
  border-left: 1px solid #d0d7de;
  padding: 16px;
  height: 100%;
}
.data-body .detail-item-panel .data-detail .label {
  font-size: 0.95em;
  opacity: 0.8;
  margin-bottom: 0;
  font-weight: 400;
}
.data-body .detail-item-panel .data-detail .value {
  margin-bottom: 15px;
  font-size: 1em;
}
.data-body .detail-item-panel .data-detail .image-preview img {
  width: 100%;
  height: 160px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.authored-course-detail .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.authored-course-detail .assignee, .authored-course-detail .assignee-head, .authored-course-detail .invited {
  display: grid;
  grid-template-columns: minmax(160px, 1.5fr) minmax(90px, 1fr) minmax(260px, 3.3fr) minmax(70px, 0.3fr);
  align-items: center;
}
.authored-course-detail .assignee .action, .authored-course-detail .assignee-head .action, .authored-course-detail .invited .action {
  display: flex;
  justify-content: flex-end;
  gap: 3px;
}
.authored-course-detail .assignee .extra, .authored-course-detail .assignee-head .extra, .authored-course-detail .invited .extra {
  display: grid;
  grid-template-columns: minmax(180px, 2fr) minmax(80px, 1fr);
}
.authored-course-detail .invited button {
  padding: 0 0.5rem;
}
.authored-course-detail .assignee-head {
  font-weight: 500;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  padding: 0.25rem 1rem;
}
.authored-course-detail .assignee, .authored-course-detail .invited {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 8px;
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
  padding: 12px 16px;
}
.authored-course-detail .assignee:hover, .authored-course-detail .invited:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-color: #ff6900;
}

.authored-courses .table-box, .authored-subjects .table-box, .personal-courses .table-box, .personal-subjects .table-box {
  height: calc(100svh - 100px);
}
.authored-courses .ant-table .ant-table-cell, .authored-subjects .ant-table .ant-table-cell, .personal-courses .ant-table .ant-table-cell, .personal-subjects .ant-table .ant-table-cell {
  vertical-align: middle;
  padding: 8px 10px;
}
.authored-courses .ant-table-row.ant-table-row-selected .ant-table-cell, .authored-subjects .ant-table-row.ant-table-row-selected .ant-table-cell, .personal-courses .ant-table-row.ant-table-row-selected .ant-table-cell, .personal-subjects .ant-table-row.ant-table-row-selected .ant-table-cell {
  background: #f1ffee !important;
}

.new-course-form {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: var(--base-1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-course-form .form-box {
  width: 600px;
  background-color: white;
  padding: 30px;
  border: 1px solid #d0d7de;
  border-radius: 8px;
}
.new-course-form .form-box .title {
  text-align: center;
  font-weight: 800;
}
.new-course-form .form-box .button-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.quiz-result-item {
  display: grid;
  grid-template-columns: 1fr 80px;
  padding: 10px 0;
}
.quiz-result-item .score {
  text-align: right;
}

.authored-course-detail .button-review {
  padding: 1px 6px;
  font-size: 0.9em;
  line-height: 20px;
  height: auto;
}

.review-tasks.has-review {
  pointer-events: none;
  opacity: 0.9;
}

.review-tasks .question-list {
  display: block;
}
.review-tasks .question-list .item {
  border: 1px solid var(--border-color);
  margin-bottom: 0.75rem;
  border-radius: 0.75rem;
  transition: 0.25s;
  cursor: pointer;
  overflow: hidden;
}
.review-tasks .question-list .item:hover {
  border-color: var(--primary);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.review-tasks .question-list .item .header {
  padding: 10px 16px;
  background-color: var(--base-2);
}
.review-tasks .question-list .label {
  font-size: 0.9em;
  font-weight: normal;
  color: var(--text-secondary);
}
.review-tasks .question-list .review-response {
  border-top: 1px solid var(--border-color);
}
.review-tasks .question-list .review-score .score-box {
  display: flex;
}
.review-tasks .question-list .score-template {
  display: flex;
}
.review-tasks .question-list .score-template span {
  padding: 4px;
  width: 40px;
  text-align: center;
  border: 1px solid var(--border-color);
  transition: 0.2s;
}
.review-tasks .question-list .score-template span:hover {
  border-color: var(--secondary);
  border-right-color: var(--secondary) !important;
  color: var(--secondary);
}
.review-tasks .question-list .score-template span:not(:last-child) {
  border-right-color: transparent;
}
.review-tasks .question-list .score-template span:first-child {
  border-radius: 6px 0 0 6px;
}
.review-tasks .question-list .score-template span:last-child {
  border-radius: 0 6px 6px 0;
}
.review-tasks .question-list .attachment-audio {
  width: 100%;
}

.review-task-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.review-task-title .task-score-box {
  display: flex;
  align-items: center;
}
.review-task-title .label {
  font-weight: normal;
}

.review-mood .mood-score {
  width: 40px;
  height: 40px;
  padding: 8px;
  border: 1px solid var(--border-color);
  transition: 0.2s;
}
.review-mood .mood-score:not(:last-child) {
  border-right-color: transparent;
}
.review-mood .mood-score:hover {
  border-color: var(--secondary);
}
.review-mood .mood-score:first-child {
  border-radius: 6px 0 0 6px;
}
.review-mood .mood-score:last-child {
  border-radius: 0 6px 6px 0;
}
.review-mood .mood-score.active {
  background-color: #fff7f1;
  border-color: var(--primary);
}

.s-container {
  display: grid;
  max-width: 1650px;
  margin: 0 auto;
}
.s-container .ant-tabs-content-holder {
  background-color: var(--base-1);
  border-left: none;
}

.is-mobile .profile .profile-tabs {
  height: calc(100svh - 50px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 50px;
  grid-template-areas: "content" "tabs";
}
.is-mobile .profile .profile-tabs .ant-tabs-nav {
  height: 100%;
  padding: 0;
  margin: 0;
  grid-area: tabs;
}
.is-mobile .profile .profile-tabs .ant-tabs-nav .ant-tabs-nav-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  width: 100%;
}
.is-mobile .profile .profile-tabs .ant-tabs-nav .ant-tabs-tab {
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  text-align: center;
  display: block;
}
.is-mobile .profile .profile-tabs .ant-tabs-content {
  height: 100%;
  overflow: hidden;
}
.is-mobile .profile .personal-courses, .is-mobile .profile .authored-courses {
  padding: 0;
}
.is-mobile .profile .personal-courses .header-box, .is-mobile .profile .authored-courses .header-box {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.75rem;
  gap: 0.4rem;
  border-bottom: 1px solid var(--border-color);
}
.is-mobile .profile .personal-courses .table-box, .is-mobile .profile .authored-courses .table-box {
  height: auto;
  padding: 0;
}
.is-mobile .profile .personal-courses .table-box .ant-table-thead, .is-mobile .profile .authored-courses .table-box .ant-table-thead {
  display: none;
}
.is-mobile .profile .personal-courses .table-box .ant-pagination, .is-mobile .profile .authored-courses .table-box .ant-pagination {
  margin: 0.5rem 0;
  justify-content: center;
}
.is-mobile .profile .personal-courses .ant-table-tbody, .is-mobile .profile .authored-courses .ant-table-tbody {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.5rem;
  gap: 0.75rem;
  background-color: var(--base-2);
}
.is-mobile .profile .personal-courses .ant-table-row, .is-mobile .profile .authored-courses .ant-table-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: "title title title title status" "title title title title status" "action action action action action";
  border-radius: 10px;
  background-color: var(--base-1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.is-mobile .profile .personal-courses .ant-table-row .ant-table-cell, .is-mobile .profile .authored-courses .ant-table-row .ant-table-cell {
  width: 100%;
  border: none;
}
.is-mobile .profile .personal-courses .ant-table-row .ant-table-cell:nth-child(4), .is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(4) {
  display: none;
}
.is-mobile .profile .personal-courses .ant-table-row .ant-table-cell:nth-child(1), .is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(1) {
  grid-area: title;
}
.is-mobile .profile .personal-courses .ant-table-row .ant-table-cell:nth-child(1) .ant-space, .is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(1) .ant-space {
  display: grid;
  grid-template-columns: 40px auto;
}
.is-mobile .profile .personal-courses .ant-table-row .ant-table-cell:nth-child(2), .is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(2) {
  grid-area: status;
  align-content: center;
}
.is-mobile .profile .personal-courses .ant-table-row .ant-table-cell:nth-child(3), .is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(3) {
  grid-area: action;
}
.is-mobile .profile .authored-courses .ant-table-row {
  grid-template-areas: "checkbox title title title action" "checkbox title title title action" "extra extra extra extra extra";
  grid-template-columns: 30px repeat(3, 1fr) 80px;
  grid-template-rows: repeat(2, 30px) 40px;
}
.is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(1) {
  grid-area: checkbox;
  padding-right: 0;
}
.is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(2) {
  grid-area: title;
}
.is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(2) .ant-space {
  min-width: unset !important;
  display: grid;
  grid-template-columns: 40px auto;
}
.is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(2) .ant-space .ant-space-item:nth-child(2) {
  width: 100%;
  word-wrap: break-word;
}
.is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(3) {
  grid-area: extra;
}
.is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(3) .extra {
  display: flex;
}
.is-mobile .profile .authored-courses .ant-table-row .ant-table-cell:nth-child(4) {
  grid-area: action;
  display: flex;
  justify-content: flex-end;
}
.is-mobile .profile .authored-courses .header-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr) 120px;
  gap: 0.5rem;
  grid-template-areas: "title title button" "query query query";
}
.is-mobile .profile .authored-courses .header-box .query {
  grid-area: query;
}
.is-mobile .profile .authored-courses .header-box .title {
  grid-area: title;
}
.is-mobile .profile .authored-courses .header-box .button {
  grid-area: button;
  width: -moz-max-content;
  width: max-content;
}
.is-mobile .profile .authored-courses .data-body {
  height: calc(100% - 95px);
}

.is-mobile .assign-course-form {
  width: 100vw;
  padding: 0.75rem;
}
.is-mobile .assign-course-form .modal-box {
  min-width: unset;
  max-width: calc(100vw - 1.5rem);
}

.is-mobile .authored-course-detail .ant-drawer-body {
  padding: 1rem;
}
.is-mobile .authored-course-detail .assignee-head {
  display: none;
}
.is-mobile .authored-course-detail .assignee, .is-mobile .authored-course-detail .invited {
  width: 100%;
  grid-template-columns: 4fr 1fr 30px;
  grid-template-areas: "name date action" "extra extra extra";
  padding: 1rem 0.75rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.is-mobile .authored-course-detail .name {
  grid-area: name;
}
.is-mobile .authored-course-detail .date {
  grid-area: date;
}
.is-mobile .authored-course-detail .extra {
  grid-area: extra;
  display: flex;
  gap: 0.5rem;
  width: 100%;
}
.is-mobile .authored-course-detail .extra .status {
  display: flex;
}
.is-mobile .authored-course-detail .extra .result .is-link {
  display: flex;
}
.is-mobile .authored-course-detail .action {
  grid-area: action;
}/*# sourceMappingURL=profile.css.map */