.course-detail-modal .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
.course-detail-modal .modal-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 1100px;
  min-width: 750px;
  background-color: white;
  height: 100vh;
  overflow: auto;
  z-index: 2;
}
.course-detail-modal .modal-box .head {
  position: sticky;
  top: 0;
  height: 150px;
  z-index: 5;
}
.course-detail-modal .modal-box .head .delete {
  position: absolute;
  right: 24px;
  top: 24px;
  transform: scale(1.2);
}
.course-detail-modal .modal-box .head .title {
  position: absolute;
  bottom: 15px;
  left: 30px;
  margin: 0;
  font-weight: 800;
  font-size: 2em;
  color: white;
}
.course-detail-modal .modal-box .head img, .course-detail-modal .modal-box .head .img-overlay {
  width: 100%;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
}
.course-detail-modal .modal-box .head .img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.course-detail-modal .modal-box .body {
  display: grid;
  grid-template-columns: 700px auto;
  gap: 30px;
}
.course-detail-modal .modal-box .body .discussion .comment-panel {
  display: grid;
  grid-template-columns: 1fr;
}
.course-detail-modal .modal-box .body .discussion .avatar img {
  height: 30px;
  width: 30px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50px;
}
.course-detail-modal .modal-box .body .review .review-item {
  display: grid;
  grid-template-columns: 1.2fr 2fr;
}
.course-detail-modal .modal-box .body .review .review-item .ant-rate {
  transform: scale(0.7);
  transform-origin: left;
}
.course-detail-modal .modal-box .body .review .review-item .user {
  display: flex;
}
.course-detail-modal .modal-box .body .review .review-item .user img {
  height: 30px;
  border-radius: 20px;
  -o-object-fit: cover;
     object-fit: cover;
}
.course-detail-modal .modal-box .body .review .review-item .user .tip {
  font-size: 0.9em;
  opacity: 0.7;
}
.course-detail-modal .modal-box .body .left-side {
  padding: 30px;
}
.course-detail-modal .modal-box .body .left-side .tabs {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}
.course-detail-modal .modal-box .body .left-side .tabs .tab {
  cursor: pointer;
}
.course-detail-modal .modal-box .body .right-side {
  position: relative;
  padding: 30px;
}
.course-detail-modal .modal-box .body .right-side .sticky-holder {
  position: sticky;
  top: 90px;
  z-index: 10;
}
.course-detail-modal .modal-box .body .right-side .box-summary {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
}
.course-detail-modal .modal-box .body .right-side .box-summary img {
  height: 180px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.course-detail-modal .modal-box .body .right-side .summary-info {
  padding: 12px;
}
.course-detail-modal .modal-box .body .right-side .summary-info .price {
  font-size: 1.3em;
  font-weight: 700;
}
.course-detail-modal .modal-box .body .right-side .summary-info .button {
  width: 100%;
}
.course-detail-modal .modal-box .body .right-side .summary-info .extra {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  text-align: center;
}
.course-detail-modal .modal-box .body .right-side .summary-info .extra div {
  display: block;
}
.course-detail-modal .modal-box .body .right-side .summary-info .extra .tip {
  opacity: 0.7;
  font-weight: 500;
}
.course-detail-modal .modal-box .module-list .ant-collapse-content-box {
  padding: 0;
}
.course-detail-modal .modal-box .module-list .content-item {
  display: grid;
  grid-template-columns: 30px auto;
  align-items: center;
  padding: 12px 0px;
  margin-left: 30px;
}
.course-detail-modal .modal-box .module-list .content-item i {
  justify-self: center;
}
.course-detail-modal .modal-box .module-list .content-item:not(:first-child) {
  border-top: 1px solid var(--border-color);
}
.course-detail-modal .modal-box .author-box {
  display: grid;
  grid-template-columns: 30px auto 90px;
  gap: 10px;
}
.course-detail-modal .modal-box .author-box .avatar {
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.course-detail-modal .modal-box .author-box .author-name {
  font-weight: 500;
  font-size: 1em;
}
.course-detail-modal .modal-box .author-box .author-contact {
  margin-top: 5px;
  display: flex;
  align-items: center;
  color: var(--icon);
}
.course-detail-modal .modal-box .author-box .author-contact span {
  padding: 6px;
  cursor: pointer;
}
.course-detail-modal .modal-box .author-box .author-contact span:hover {
  color: var(--secondary);
}

.is-mobile .course-detail-modal .modal-box {
  min-width: 100%;
  width: 100vw;
}
.is-mobile .course-detail-modal .modal-box .head {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.is-mobile .course-detail-modal .modal-box .head, .is-mobile .course-detail-modal .modal-box .head .img-overlay, .is-mobile .course-detail-modal .modal-box .head img {
  height: 175px;
}
.is-mobile .course-detail-modal .modal-box .head .title {
  left: 0;
  padding: 1rem;
}
.is-mobile .course-detail-modal .modal-box .body {
  grid-template-columns: 1fr;
}
.is-mobile .course-detail-modal .modal-box .body .left-side {
  width: 100vw;
  padding: 1rem;
}
.is-mobile .course-detail-modal .modal-box .body .description {
  width: 100%;
}
.is-mobile .course-detail-modal .modal-box .foot {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 0.75rem;
  z-index: 99;
  background-color: white;
}
.is-mobile .course-detail-modal .modal-box .author-section {
  padding: 1rem;
  margin-top: 0 !important;
}/*# sourceMappingURL=course-detail-modal.css.map */