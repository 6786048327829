@import "../../../styles/color"

$complete: #e5f9f8
$active: #fff6f0

.course-enroll, .course-preview,  .course-public, .course-Enroll, .course-Preview,  .course-Public
    display: grid
    grid-template-columns: 400px auto
    height: 100vh
    width: 100vw
    transition: .3s

    .attachment-item .item-group
        display: flex
        justify-content: space-between
        cursor: pointer
        padding: 5px 10px

        .icon-delete
            opacity: 0
            transition: .2s

        &:hover
            background-color: var(--base-2)
            .icon-delete
                opacity: 1

    .loading-bar
        width: 0
        height: 3px
        background-color: $secondary
        position: absolute

        &.is-loading
            animation: is-loading .3s forwards

    @keyframes is-loading
        100%
            width: 100%

    p[data-f-id="pbf"]
        display: none

    &.hide-panel
        grid-template-columns: 0 auto
        .content-panel
            left: -400px
            opacity: 0

    .content-panel
        height: 100%
        width: 100%
        position: relative
        left: 0px
        opacity: 1
        transition: .2s
        overflow: hidden

        .active
            border-right: 4px solid
            border-right-color: $primary!important
            background-color: $active!important

        .header
            display: flex
            border-bottom: $border
            padding: 16px
            align-items: center
            height: 60px

            img
                width: 40px

        .body
            height: calc( 100% - 120px )
            overflow: auto
            background-color: $base

        .footer
            height: 60px
            display: flex
            padding: 16px
            border-top: $border
            align-items: center

            img
                height: 30px
                border-radius: 20px
                object-fit: cover

            .user-enroll
                display: flex
                align-items: center

    .main-panel
        position: relative
        border-left: $border
        background-color: $base
        overflow: auto

        .brand-header-panel
            width: 75%
            max-width: 1024px
            min-width: 600px
            height: 100px
            margin: auto
            margin-top: 10px
            display: flex
            justify-content: center
            background-color: none

            .image-banner
                width: 100%
                border-radius: 10px
                object-fit: cover

            .image-logo
                width: 300px
                height: 80px
                object-fit: contain

        .colapse-button
            cursor: pointer
            position: absolute
            top: 10px
            left: 0
            width: 35px
            height: 35px
            border-radius: 0 5px 5px 0
            border: $border
            border-left: none
            display: flex
            justify-content: center
            align-items: center
            background-color: white

            &:hover
                background-color: $light-1

        .delete
            position: absolute
            top: 16px
            right: 24px
            transform: scale(1.5)
            z-index: 99

        .body
            display: block
            height: calc( 100% - 60px )
            max-height: calc( 100% - 60px )
            min-height: calc( 100% - 60px )
            overflow: auto
        .footer
            display: block
            height: 60px
            padding: 0
            display: grid
            grid-template-columns: repeat(3,1fr)
            border-top: $border

            .next, .previous
                cursor: pointer
                display: flex
                align-items: center
                padding: 16px 24px

                &:hover
                    background-color: $secondary
                    color: white

                &.disable
                    pointer-events: none
                    opacity: .5

                p
                    margin: 0
                    font-weight: 500
                    letter-spacing: 1px
                    text-transform: uppercase
                    margin: auto
            .next
                justify-content: flex-end

            .previous
                justify-content: flex-start

    .content-panel .content
        cursor: pointer
        margin: 0

        .tab-1
            padding-left: 30px!important
        .content-header
            padding: 16px
            display: grid
            grid-template-columns: auto 30px
            border-bottom: $border

            p
                margin-bottom: 0

            &.complete
                background-color: $complete!important

        .expand
            cursor: pointer
            text-align: right

        .item
            padding: 16px
            border-bottom: $border
            border-color: #00000010
            display: grid
            grid-template-columns: 24px auto
            align-items: center
            justify-items: center
            background-color: white

            &.complete
                background-color: $complete!important

            p
                justify-self: start

            &:hover
                background-color: #f9f9f9

    // content views 
    .main-panel .body
        .module-view, .lesson-view, .task-view, .quiz-view, .question-view
            width: 100%
            display: flex
            justify-content: center
            align-items: center
            background-color: $base
            margin-top: 24px

        .module-view, .task-view, .quiz-view, .end-course
            .content-body
                max-width: 800px
                min-width: 600px
                border-radius: 10px
                padding: 24px
                // text-align: center
                height: auto
                display: flex
                flex-direction: column
                // justify-content: center
                // align-items: center
                background-color: var(--base-1)
                margin-bottom: 50px

                .quiz-image
                    height: 400px

                .title
                    font-size: 1.5em
                    font-weight: 800

                .module-title, .quiz-title, .task-title
                    text-align: center

        .lesson-view, .question-view
            display: block
            width: 75%
            max-width: 1024px
            min-width: 600px
            margin: auto
            overflow: auto
            background-color: white
            padding: 24px
            margin-top: 24px

            .content-head
                height: 50px
                border-bottom: $border
                font-weight: 700
                font-size: 1.2em

            .content-body
                margin: auto
                margin-bottom: 30px

        .question-view

            .tip
                opacity: .8

            .question-label
                text-transform: uppercase
                font-size: .9em
                letter-spacing: 1px
                opacity: .9
                border-bottom: $border
                padding-bottom: 10px
                margin-bottom: 10px

            .answer-box
                .answer
                    cursor: pointer
                    display: flex
                    padding: 15px
                    border: $border
                    background-color: white
                    margin-bottom: 10px
                    border-radius: 5px

                    &:hover
                        border-color: #0084ff

                    &.selected
                        background-color: #d8ebfd
                        border-color: #0084ff

                    &.is-true
                        background-color: #e5fffd
                        border-color: #00a397
                    &.is-false
                        background-color: #fff1ef
                        border-color: #ff3300

                .option-index
                    padding-right: 15px
                    border-right: $border

                .option-value
                    margin: 0 10px

                .answer-mood
                    align-items: center

                .mood-icon
                    width: 24px

        .lesson-view
            height: 100%
            max-height: calc( 100svh - 100px )
            .lesson-attachment
                padding: 1.5em 0
                border-top: 1px dashed rgba(0, 0, 0, .1)

                h3
                    font-size: 1.1em
                    font-weight: 700
                    margin-bottom: .5em

        .end-course
            display: flex
            justify-content: center
            align-items: center
            margin-top: 50px

            .endcourse
                display: flex
                justify-content: center
                align-items: center
                flex-direction: column

.enroll-failed
    width: 100vw
    height: calc( 100svh - 110px )
    text-align: center
    padding: 3rem

    p
        font-size: 1.5rem
        font-weight: 500

.course-Enroll, .course-Preview, .course-Public
    .main-panel .body .lesson-view
        height: auto
        max-height: unset
        margin-bottom: 3rem
        border-radius: 10px

.course-Enroll, .course-Preview, .course-Public
    .main-panel .body .question-view
        border-radius: 10px

//-----------------------------------------------------------------------------
// MOBILE
//-----------------------------------------------------------------------------

.is-mobile .course-Enroll, .is-mobile .course-Preview, .is-mobile .course-Public
    grid-template-columns: 1fr
    grid-template-rows: 60px auto
    .content-panel, .colapse-button, .close-button
        display: none

    .header
        display: grid
        grid-template-columns: 40px auto 30px
        padding: .75rem
        align-items: center

        .title
            margin-bottom: 0
        .delete
            scale: 1.2
        img
            width: 30px

    .footer
        grid-template-columns: 1fr 0 1fr

    .main-panel .body
        width: 100%
        padding: 0 .75rem

        .question-view
            min-width: calc( 100vw - 1.5rem )
            max-width: 100vw

        .module-view .content-body, .task-view .content-body, .quiz-view .content-body, .end-course .content-body, .lesson-view
            min-width: calc( 100vw - 1.5rem )
            max-width: 100vw

        .end-course
            p
                text-align: center
                margin-bottom: 1rem

    .main-panel .brand-header-panel
        width: 100%
        min-width: 0
        height: 75px
