.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.classroom {
  width: 100vw;
}
.classroom .no-border {
  border: none !important;
}
.classroom .tab-switch {
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 185px;
  padding: 5px 10px;
  border: 1px solid #d0d7de;
  border-radius: 6px;
  background-color: white;
  transition: 0.3s;
}
.classroom.collapse .tab-switch {
  left: 40px;
}
.classroom.collapse .body {
  grid-template-columns: 60px auto;
}
.classroom.collapse .body .ant-tabs-tab .tab-item {
  grid-template-columns: 30px 0;
  overflow: hidden;
}
.classroom .head {
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #d0d7de;
  padding: 24px;
}
.classroom .head .back {
  margin-right: 1em;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #fafafd;
  border-radius: 6px;
}
.classroom .body {
  display: grid;
  grid-template-columns: 200px auto;
  height: calc(100vh - 100px);
  background-color: #fafafd;
  transition: 0.3s;
}
.classroom .body .ant-tabs-tab {
  margin: 0 !important;
  padding: 8px 20px;
}
.classroom .body .ant-tabs-tab .tab-item {
  display: grid;
  grid-template-columns: 30px auto;
}
.classroom .body .ant-tabs-tab .tab-item .icon {
  line-height: 30px;
}
.classroom .body .ant-tabs-content-holder {
  padding: 0;
}
.classroom .body .ant-tabs-tabpane.ant-tabs-tabpane-active {
  padding: 0 !important;
}
.classroom .classroom-member .has-avatar {
  display: flex;
  align-items: center;
}
.classroom .classroom-member .member-avatar {
  height: 24px;
  width: 24px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
}
.classroom .classroom-preferences {
  width: 50%;
  min-width: 600px;
  max-width: 1000px;
  background-color: white;
  border: 1px solid #d0d7de;
  border-radius: 10px;
  padding: 24px;
  margin: 24px auto;
  margin-bottom: 50px;
}
.classroom .classroom-preferences .field-description .fr-toolbar .fr-command.fr-btn svg {
  width: 18px;
}
.classroom .classroom-preferences .field-description .fr-toolbar .fr-command.fr-btn {
  height: 30px;
}
.classroom .ant-tabs-content-holder {
  height: calc(100vh - 100px);
  overflow: auto;
}
.classroom .classroom-tasks .task-quiz .header, .classroom .classroom-tasks .task-lesson .header {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #d0d7de;
}
.classroom .classroom-tasks .task-quiz .back, .classroom .classroom-tasks .task-lesson .back {
  border-radius: 6px;
  padding: 5px 10px;
  background-color: #fafafd;
  cursor: pointer;
  transition: 0.3s;
}
.classroom .classroom-tasks .task-quiz .back:hover, .classroom .classroom-tasks .task-lesson .back:hover {
  background-color: rgba(0, 0, 0, 0.062745098);
}
.classroom .classroom-tasks .task-quiz .body, .classroom .classroom-tasks .task-lesson .body {
  display: grid;
  grid-template-columns: auto 300px;
  height: calc(100vh - 140px);
}
.classroom .classroom-tasks .task-quiz .body .setting-panel, .classroom .classroom-tasks .task-lesson .body .setting-panel {
  border-left: 1px solid #d0d7de;
}
.classroom .classroom-tasks .task-lesson .editor-box {
  padding: 24px;
  background-color: white;
  width: 1100px;
  margin: auto;
}
.classroom .classroom-tasks .task-lesson .editor-box .fr-wrapper {
  height: calc(100vh - 360px) !important;
}
.classroom .classroom-tasks .task-quiz .question-box {
  margin: 30px auto;
  width: 900px;
}
.classroom .classroom-tasks .task-quiz .question-box .question-list {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #d0d7de;
  background-color: white;
  width: 100%;
}
.classroom .classroom-tasks .task-quiz .question-box .question-list-item {
  position: relative;
  border: 1px solid #d0d7de;
  background: #fafafd;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 10px;
}
.classroom .classroom-tasks .task-quiz .question-box .question-list-item .title-group {
  display: grid;
  grid-template-columns: 24px auto 40px;
}
.classroom .classroom-tasks .task-quiz .question-box .question-list-item .option {
  height: 30px;
  position: absolute;
  right: 16px;
  top: calc(50% - 15px);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.classroom .classroom-tasks .task-quiz .question-box .question-list-item .option i {
  padding: 8px;
  border-radius: 4px;
}
.classroom .classroom-tasks .task-quiz .question-box .question-list-item .option i:hover {
  background-color: rgba(0, 0, 0, 0.062745098);
}
.classroom .classroom-tasks .task-quiz .question-box .question-list-item:hover .option {
  visibility: visible;
  opacity: 1;
}
.classroom .classroom-programs .program .ant-tabs-content-holder {
  height: calc(100vh - 150px);
  overflow: auto;
}
.classroom .classroom-programs .program .header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d0d7de;
}
.classroom .classroom-programs .program .header .back {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 6px;
}
.classroom .classroom-programs .program .header .back:hover {
  background-color: rgba(0, 0, 0, 0.062745098);
}
.classroom .classroom-programs .program .body {
  display: grid;
  grid-template-columns: auto 300px;
  height: calc(100vh - 150px);
}
.classroom .classroom-programs .program .body .ant-tabs {
  display: grid;
  grid-template-columns: 180px auto;
  background-color: #fafafd;
}
.classroom .classroom-programs .program .body .ant-tabs .ant-tabs-tab .tab-item {
  grid-template-columns: 30px auto;
}
.classroom .classroom-programs .program .body .setting {
  height: calc(100vh - 150px);
  border-left: 1px solid #d0d7de;
}
.classroom .classroom-programs .add-program {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
}
.classroom .classroom-programs .add-program .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.classroom .classroom-programs .add-program .modal-box {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  background-color: white;
  border-radius: 6px;
}
.classroom .classroom-programs .add-program .modal-header {
  border-bottom: 1px solid #d0d7de;
  display: flex;
  align-items: center;
}
.classroom .classroom-programs .form-add-courses {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.classroom .classroom-programs .form-add-courses .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.classroom .classroom-programs .form-add-courses .modal-box {
  background-color: white;
  border-radius: 8px;
  position: absolute;
  top: 30px;
  left: 50%;
  width: 900px;
  transform: translateX(-50%);
}
.classroom .classroom-programs .form-add-courses .modal-header {
  border-bottom: 1px solid #d0d7de;
  background-color: #fafafd;
  border-radius: 8px 8px 0 0;
}
.classroom .classroom-programs .form-add-courses .course-item {
  display: grid;
  grid-template-columns: 1fr 100px repeat(4, minmax(40px, 60px));
}
.classroom .classroom-member .ant-table-cell, .classroom .classroom-preferences .ant-table-cell, .classroom .classroom-modules .ant-table-cell, .classroom .classroom-tasks .ant-table-cell {
  padding: 10px 16px;
}
.classroom .classroom-member .ant-table-cell.action, .classroom .classroom-preferences .ant-table-cell.action, .classroom .classroom-modules .ant-table-cell.action, .classroom .classroom-tasks .ant-table-cell.action {
  text-align: right;
}
.classroom .classroom-member .ant-table-cell .tip, .classroom .classroom-preferences .ant-table-cell .tip, .classroom .classroom-modules .ant-table-cell .tip, .classroom .classroom-tasks .ant-table-cell .tip {
  font-size: 0.85em;
  opacity: 0.7;
}/*# sourceMappingURL=classroom.css.map */