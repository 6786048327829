@import "../../styles/color"

.setting
    background-image: linear-gradient(to right, var(--base-2), var(--base-2), var(--base-1), var(--base-1))
    height: calc( 100vh - 50px )

    .ant-tabs-nav .ant-tabs-tab
        padding: 8px 35px 8px 24px

    .container
        .edit-avatar
            text-align: right
            .ant-upload-list-item-container
                width: 200px!important
                height: 200px!important

            .ant-upload.ant-upload-select
                width: 200px!important
                height: 200px!important

    .head h3
        font-size: 1.5em
        font-weight: 800

    .edit-profile, .edit-account, .billing-info
        padding: 0 16px

        .profile-form
            display: grid
            grid-template-columns: 1fr 250px

        .form
            padding: 24px
            border: $border
            background-color: white
            border-radius: 10px
            .field-group
                display: flex
                gap: 10px

            .field
                width: 100%

            .ant-input
                width: 100%

            .label
                font-weight: 500
                font-size: .9em

            .tip
                opacity: .7

    .setting-tabs
        .ant-tabs-nav-list
            padding: 10px 0
            background-color: transparent
            max-height: calc( 38px * 3 + 20px )
            border: none
            border-radius: 6px
        .tab
            cursor: pointer

        .ant-tabs-tab
            margin: 0!important
            &:hover
                color: var(--secondary)

        .ant-tabs-content-holder
            border: none

.role-upgrade-panel
    position: relative
    width: 100%
    height: 240px
    display: flex
    justify-content: flex-end
    align-items: center
    padding: 24px
    border-radius: 10px
    overflow: hidden

    .bg
        width: 100%
        background-color: #fc8601
    img
        height: 100%
        position: absolute
        object-fit: contain
        object-position: left
        left: 0

.setting .billing-info .billing
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 20px

    h6
        opacity: .8

    p
        font-size: 1.3em
        font-weight: 400
        margin-bottom: 10px

    .balance, .income, .spending
        background-color: var(--base-1)
        padding: 1rem 1.5rem
        border-radius: 10px
        border: 1px solid var(--border-color)

.setting
    .ant-tabs-content-holder
        min-height: calc( 100svh - 50px )
        background-color: var(--base-1)
        padding-top: 1.5rem

    .ant-tabs-nav-wrap
        padding-top: 1rem

// -------------------------------------
// -------------- MOBILE ---------------
// -------------------------------------

.is-mobile .setting
    .setting-tabs
        height: calc( 100svh - 50px )
        display: grid
        grid-template-columns: 1fr
        grid-template-rows: 1fr 50px
        grid-template-areas: "content" "tabs"

    .ant-tabs-content-holder
        min-height: unset
        padding: 0

    .ant-tabs-nav, .ant-tabs-nav-wrap
        margin: 0
        padding: 0

    .ant-tabs-nav-list
        display: grid
        grid-template-columns: repeat(3, 1fr)
        padding: 0
        width: 100%

        .ant-tabs-tab
            padding: .5rem
            justify-content: center

    // EDIT PROFILE

    .edit-profile, .billing-info, .edit-account
        height: calc( 100svh - 100px )
        overflow-y: auto
        overflow-x: hidden
        padding: 1rem

    .edit-profile .profile-form
        grid-template-columns: 1fr
        gap: 1rem
        grid-template-areas: "avatar" "form"

        .edit-avatar
            text-align: left
            grid-area: avatar

        .anticon svg
            fill: var(--icon)

    // BILLING INFO
    .billing-info
        .billing
            grid-template-columns: 1fr
            gap: .5rem

        .ant-pagination
            justify-content: center
