.cancel-payment
    padding: 3rem
    height: calc( 100svh - 160px )

    .container
        text-align: center

    p
        font-size: 1.5rem
        font-weight: 500
        margin-bottom: 2rem

    img
        width: 400px
        height: 400px
        margin: 0 auto

.checkout-modal
    .checkout-item
        display: grid
        grid-template-columns: 60px 1fr
        align-items: center
        padding: 5px 0
        gap: 10px

        h6
            font-weight: 500

        img
            width: 60px
            height: 60px
            border-radius: 8px
            object-fit: cover

.success-payment
    .container
        height: calc( 100svh - 160px )
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column

    h6
        font-size: 1.5rem
        font-weight: 500
        margin-bottom: 1rem

$checkmark-size: 120px

.checkmark__circle
    stroke-dasharray: 166
    stroke-dashoffset: 166
    stroke-width: 2
    stroke-miterlimit: 10
    stroke: green
    fill: none
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards

.checkmark
    position: relative
    width: $checkmark-size
    height: $checkmark-size
    border-radius: 50%
    display: block
    stroke-width: 2
    stroke: green
    stroke-miterlimit: 10
    margin: 5% auto
    box-shadow: inset 0px 0px 0px #7ac142
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both

    &__check
        transform-origin: 50% 50%
        stroke-dasharray: 48
        stroke-dashoffset: 48
        animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards

@keyframes stroke
    100%
        stroke-dashoffset: 0

@keyframes scale
    0%, 100%
        transform: none

    50%
        transform: scale3d(1.1, 1.1, 1)

@keyframes fill
    100%
        box-shadow: inset 0px 0px 0px 80px #fff

// MOBILE 

.cancel-payment, .success-payment
    height: calc( 100svh - 50px )
    padding: 1rem
    display: grid
    place-items: center

    .container
        img
            width: 85vw
            height: 85vw
