.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.global-search {
  min-height: calc(100vh - 128px);
  height: calc(100vh - 50px);
  padding: 30px 0 0 0;
  overflow: auto;
  position: relative;
}
.global-search .container {
  display: grid;
  grid-template-columns: 250px auto;
  gap: 30px;
}
.global-search .container.fit-screen {
  min-height: calc(100vh - 190px);
}
.global-search .container .public-course-card {
  max-height: 270px;
}
.global-search .container .public-course-card .card-head {
  height: 140px;
}
.global-search .filter-box {
  width: 100%;
  border: 1px solid #d0d7de;
  border-radius: 8px;
  position: sticky;
  top: 0;
  height: -moz-max-content;
  height: max-content;
}
.global-search .filter-box .filter {
  border-top: 1px solid #d0d7de;
}
.global-search .filter-box .filter h4 {
  font-weight: 500;
}
.global-search .contents {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}/*# sourceMappingURL=search.css.map */