.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.home {
  overflow: auto;
}
.home .course-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
.home .course-list-container {
  min-height: calc(100svh - 150px);
}
@media screen and (min-width: 1600px) {
  .home .container {
    max-width: 1500px !important;
  }
  .home .course-list {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (max-width: 1215px) {
  .home .container {
    max-width: 1150px !important;
  }
  .home .course-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

.is-mobile .home .course-list {
  grid-template-columns: 1fr;
}
.is-mobile .home .course-list-container {
  min-height: calc(100svh - 150px);
}

.is-mobile.is-tablet .home .course-list {
  grid-template-columns: 1fr 1fr;
}
.is-mobile.is-tablet .home .course-list-container {
  min-height: calc(100svh - 150px);
}

.is-mobile.is-tablet.is-landscape .home .course-list {
  grid-template-columns: 1fr 1fr 1fr;
}/*# sourceMappingURL=home.css.map */