@import "../../styles/color"

.global-search
    min-height: calc( 100vh - 128px )
    height: calc( 100vh - 50px )

    padding: 30px 0 0 0
    overflow: auto
    position: relative

    .container
        display: grid
        grid-template-columns: 250px auto
        gap: 30px

        &.fit-screen
            min-height: calc( 100vh - 190px )
        .public-course-card
            max-height: 270px

            .card-head
                height: 140px

    .filter-box
        width: 100%
        border: $border
        border-radius: 8px
        position: sticky
        top: 0
        height: max-content

        .filter
            border-top: $border

            h4
                font-weight: 500

    .contents
        display: grid
        grid-template-columns: repeat(4, 1fr)
        gap: 12px
