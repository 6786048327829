$primary: #ff6900
$primary-bg: #fff7f1
$black: #24292f
$dark: #57606a
$secondary: #00a397
$secondary-transparent: #00a39810
$base: #fafafd
$light-1: #f6f8fa
$light-2: #f6f8fa
$light-4: #d0d7de

$info: #053f83
$info-bg: #F3F8FF

$success: #067A72
$success-bg: #eefdfb

$danger: #f76979
$danger-transparent: #f7697917

$icon: #57606a
$scroll: #8d98a5
$transparent: #00000010
$border: 1px solid $light-4
$box-shadow: 0 10px 10px $transparent
$background-base: #fafafd

.button
    &:hover
        filter: brightness(.9)

.button.is-primary
    background-color: $primary!important
    border: none
    color: white

.button.is-danger
    background-color: $danger!important
    border: none
    color: white

.button.is-outlined.is-danger

    &:hover
        // border: none
        color: white
        background-color: $danger!important

.button.is-secondary
    background-color: $secondary!important
    color: white
    font-weight: 500
    border: none

    &:hover
        color: white

.button.is-outlined.is-primary
    color: $primary!important
    border-color: $primary!important
    background-color: $base!important

.button.is-outlined
    border: $border
    background-color: $base!important

.button.is-outlined.is-secondary
    border: 1px solid!important
    color: $secondary!important
    border-color: $secondary!important
    background-color: $base!important

    &:hover
        background-color: $secondary!important
        color: white!important
        filter: brightness(1)

button
    &.is-danger
        background-color: $danger!important
        color: white!important
