.course-builder {
  background-color: var(--base-1);
  height: calc(100vh - 55px);
  display: grid;
  grid-template-columns: 400px auto;
}
.course-builder .main-panel {
  height: calc(100vh - 55px);
  border-right: 1px solid var(--border-color);
}
.course-builder .main-panel .header-box {
  border-bottom: 1px solid var(--border-color);
  padding: 10px 15px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.course-builder .main-panel .header-box .button {
  height: 32px;
  font-weight: 400;
}
.course-builder .main-panel .header-box p {
  letter-spacing: 1px;
  font-weight: 500;
}
.course-builder .main-panel .card-content-box {
  padding: 10px;
  max-height: calc(100% - 50px);
  overflow: auto;
}
.course-builder .main-panel .card-content-box::-webkit-scrollbar {
  width: 8px;
}
.course-builder .main-panel .card-content-box::-webkit-scrollbar-track {
  background-color: var(--base-1);
}
.course-builder .main-panel .card-content-box::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.course-builder .main-panel .card-content-box .card-content {
  padding: 0;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--base-1);
}
.course-builder .main-panel .card-content-box .card-content:hover {
  box-shadow: var(--box-shadow);
  border-color: var(--secondary);
}
.course-builder .main-panel .card-content-box .card-content:hover .header .option {
  opacity: 1;
}
.course-builder .main-panel .card-content-box .card-content .body {
  border-top: 1px solid var(--border-color);
  padding: 0;
  min-height: 40px;
}
.course-builder .main-panel .card-content-box .card-content .header {
  padding: 16px;
  padding-right: 8px;
  background-color: white;
  display: grid;
  grid-template-columns: auto 30px;
  font-weight: 500;
  font-size: 1em;
}
.course-builder .main-panel .card-content-box .card-content .header .option {
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.9em;
  opacity: 0;
  cursor: pointer;
  transition: 0.3s;
}
.course-builder .main-panel .card-content-box .card-content .header .option:hover {
  background-color: var(--black-10);
}
.course-builder .main-panel .card-content-box .card-content .footer {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 10px;
  overflow: hidden;
  background-color: white;
}
.course-builder .main-panel .card-content-box .card-content .footer .button {
  font-weight: 400;
  height: 30px;
  padding: 5px 9px;
  font-size: 0.85em;
}
.course-builder .main-panel .card-content-box .card-content .footer .button.import i {
  font-size: 1.2rem;
  padding: 0;
  line-height: normal;
}
.course-builder .main-panel .card-content .content {
  padding: 12px 10px;
  min-height: 60px;
  position: relative;
}
.course-builder .main-panel .card-content .content:not(:first-child) {
  border-top: 1px solid var(--border-color);
}
.course-builder .main-panel .card-content .content:hover {
  background-color: var(--base-1);
}
.course-builder .main-panel .card-content .content:hover .option {
  opacity: 1;
}
.course-builder .main-panel .card-content .content .title-box {
  display: grid;
  grid-template-columns: 24px auto 50px;
}
.course-builder .main-panel .card-content .content .title-box i {
  margin: auto;
}
.course-builder .main-panel .card-content .content .option {
  position: absolute;
  right: 10px;
  top: 12px;
  opacity: 0;
  z-index: 2;
  transition: 0.3s;
}
.course-builder .main-panel .card-content .content .option i {
  padding: 8px;
  font-size: 0.9em;
  cursor: pointer;
  border-radius: 4px;
}
.course-builder .main-panel .card-content .content .option i:hover {
  background-color: var(--black-10);
}
.course-builder .main-body .empty-state {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.course-builder .main-body .empty-state .tip {
  width: 400px;
}
.course-builder .main-body .empty-state .title {
  opacity: 0.5;
  font-weight: 800;
}
.course-builder .main-body .edit-module .header, .course-builder .main-body .edit-lesson .header, .course-builder .main-body .edit-task .header {
  width: 100%;
  padding: 0px 24px;
  border-bottom: 1px solid var(--border-color);
  height: 50px;
}
.course-builder .main-body .edit-module .header .title, .course-builder .main-body .edit-lesson .header .title, .course-builder .main-body .edit-task .header .title {
  line-height: 53px;
}
.course-builder .main-body .edit-module .form-box, .course-builder .main-body .edit-lesson .form-box, .course-builder .main-body .edit-task .form-box {
  width: 100%;
}
.course-builder .main-body .edit-module .form-box .form, .course-builder .main-body .edit-lesson .form-box .form, .course-builder .main-body .edit-task .form-box .form {
  padding: 24px;
  width: 600px;
  margin: auto;
  background-color: white;
  margin-top: 50px;
}
.course-builder .main-body .edit-module .form-box .form .textarea, .course-builder .main-body .edit-lesson .form-box .form .textarea, .course-builder .main-body .edit-task .form-box .form .textarea {
  height: 150px;
}
.course-builder .main-body .edit-module .form-box .form .flex-group, .course-builder .main-body .edit-lesson .form-box .form .flex-group, .course-builder .main-body .edit-task .form-box .form .flex-group {
  display: flex;
  justify-content: space-between;
}
.course-builder .main-body .edit-lesson {
  height: 100%;
}
.course-builder .main-body .edit-lesson .header, .course-builder .main-body .edit-lesson .header-breadcrumb {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.course-builder .main-body .edit-lesson .header .input, .course-builder .main-body .edit-lesson .header-breadcrumb .input {
  width: 600px;
}
.course-builder .main-body .edit-lesson .header .title, .course-builder .main-body .edit-lesson .header-breadcrumb .title {
  font-size: 1.2em;
}
.course-builder .main-body .edit-lesson .header i, .course-builder .main-body .edit-lesson .header-breadcrumb i {
  font-size: 0.9em;
}
.course-builder .main-body .edit-lesson .header .header-form, .course-builder .main-body .edit-lesson .header-breadcrumb .header-form {
  display: none;
}
.course-builder .main-body .edit-lesson .header .fa-pen, .course-builder .main-body .edit-lesson .header-breadcrumb .fa-pen {
  opacity: 0.5;
  padding: 10px 10px;
  border-radius: 5px;
}
.course-builder .main-body .edit-lesson .header .fa-pen:hover, .course-builder .main-body .edit-lesson .header-breadcrumb .fa-pen:hover {
  opacity: 1;
  background-color: var(--black-10);
}
.course-builder .main-body .edit-lesson .header.edit .header-form, .course-builder .main-body .edit-lesson .header-breadcrumb.edit .header-form {
  display: flex;
}
.course-builder .main-body .edit-lesson .header.edit .header-breadcrumb, .course-builder .main-body .edit-lesson .header-breadcrumb.edit .header-breadcrumb {
  display: none;
}
.course-builder .main-body .edit-lesson .form-box {
  height: 100%;
  display: grid;
  grid-template-columns: auto 300px;
}
.course-builder .main-body .edit-lesson .form-box .form {
  width: 80%;
  max-width: 1100px;
  min-width: 560px;
}
.course-builder .main-body .edit-lesson .form-box .form.lesson-form {
  border-radius: 10px;
}
.course-builder .main-body .edit-lesson .form-box .panel-setting {
  height: 100%;
  border-left: 1px solid var(--border-color);
}
.course-builder .main-body .edit-lesson .form-box .panel-setting .ant-upload, .course-builder .main-body .edit-lesson .form-box .panel-setting .ant-upload .ant-btn {
  width: 100%;
}
.course-builder .main-body .edit-lesson .form-box .panel-setting .add-attachment {
  width: 100%;
}
.course-builder .main-body .edit-lesson .form-box .panel-setting .input-attachment {
  display: none;
}
.course-builder .main-body .edit-quiz, .course-builder .main-body .edit-task, .course-builder .main-body .edit-lesson {
  height: 100%;
}
.course-builder .main-body .edit-quiz .header, .course-builder .main-body .edit-task .header, .course-builder .main-body .edit-lesson .header {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.course-builder .main-body .edit-quiz .header.edit .header-breadcrumb, .course-builder .main-body .edit-task .header.edit .header-breadcrumb, .course-builder .main-body .edit-lesson .header.edit .header-breadcrumb {
  display: none;
}
.course-builder .main-body .edit-quiz .header.edit .header-form, .course-builder .main-body .edit-task .header.edit .header-form, .course-builder .main-body .edit-lesson .header.edit .header-form {
  display: flex;
}
.course-builder .main-body .edit-quiz .header .header-breadcrumb, .course-builder .main-body .edit-task .header .header-breadcrumb, .course-builder .main-body .edit-lesson .header .header-breadcrumb {
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
}
.course-builder .main-body .edit-quiz .header .title, .course-builder .main-body .edit-task .header .title, .course-builder .main-body .edit-lesson .header .title {
  font-size: 1.1em;
  margin: 0;
}
.course-builder .main-body .edit-quiz .header .fa-pen, .course-builder .main-body .edit-task .header .fa-pen, .course-builder .main-body .edit-lesson .header .fa-pen {
  font-size: 0.9em;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.course-builder .main-body .edit-quiz .header .fa-pen:hover, .course-builder .main-body .edit-task .header .fa-pen:hover, .course-builder .main-body .edit-lesson .header .fa-pen:hover {
  background-color: var(--black-10);
}
.course-builder .main-body .edit-quiz .header .header-form, .course-builder .main-body .edit-task .header .header-form, .course-builder .main-body .edit-lesson .header .header-form {
  display: none;
}
.course-builder .main-body .edit-quiz .header .header-form .input, .course-builder .main-body .edit-task .header .header-form .input, .course-builder .main-body .edit-lesson .header .header-form .input {
  width: 600px;
}
.course-builder .main-body .edit-quiz .form-box, .course-builder .main-body .edit-task .form-box, .course-builder .main-body .edit-lesson .form-box {
  height: calc(100vh - 105px);
  display: grid;
  grid-template-columns: auto 300px;
}
.course-builder .main-body .edit-quiz .form-box .form-body, .course-builder .main-body .edit-task .form-box .form-body, .course-builder .main-body .edit-lesson .form-box .form-body {
  width: 100%;
  overflow: auto;
}
.course-builder .main-body .edit-quiz .setting-panel, .course-builder .main-body .edit-task .setting-panel, .course-builder .main-body .edit-lesson .setting-panel {
  height: 100%;
  overflow: auto;
  border-left: 1px solid var(--border-color);
}
.course-builder .main-body .edit-quiz .question-panel, .course-builder .main-body .edit-task .question-panel, .course-builder .main-body .edit-lesson .question-panel {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px;
}
.course-builder .main-body .edit-quiz .question-panel .button, .course-builder .main-body .edit-task .question-panel .button, .course-builder .main-body .edit-lesson .question-panel .button {
  width: 100%;
}
.course-builder .main-body .edit-quiz .question-panel .question-panel-box, .course-builder .main-body .edit-task .question-panel .question-panel-box, .course-builder .main-body .edit-lesson .question-panel .question-panel-box {
  margin: auto;
  width: 600px;
}
.course-builder .main-body .edit-quiz .question-panel, .course-builder .main-body .edit-quiz .setting-panel, .course-builder .main-body .edit-quiz .form-body, .course-builder .main-body .edit-task .question-panel, .course-builder .main-body .edit-task .setting-panel, .course-builder .main-body .edit-task .form-body, .course-builder .main-body .edit-lesson .question-panel, .course-builder .main-body .edit-lesson .setting-panel, .course-builder .main-body .edit-lesson .form-body {
  /* Track */
  /* Handle */
}
.course-builder .main-body .edit-quiz .question-panel::-webkit-scrollbar, .course-builder .main-body .edit-quiz .setting-panel::-webkit-scrollbar, .course-builder .main-body .edit-quiz .form-body::-webkit-scrollbar, .course-builder .main-body .edit-task .question-panel::-webkit-scrollbar, .course-builder .main-body .edit-task .setting-panel::-webkit-scrollbar, .course-builder .main-body .edit-task .form-body::-webkit-scrollbar, .course-builder .main-body .edit-lesson .question-panel::-webkit-scrollbar, .course-builder .main-body .edit-lesson .setting-panel::-webkit-scrollbar, .course-builder .main-body .edit-lesson .form-body::-webkit-scrollbar {
  width: 6px;
}
.course-builder .main-body .edit-quiz .question-panel::-webkit-scrollbar-track, .course-builder .main-body .edit-quiz .setting-panel::-webkit-scrollbar-track, .course-builder .main-body .edit-quiz .form-body::-webkit-scrollbar-track, .course-builder .main-body .edit-task .question-panel::-webkit-scrollbar-track, .course-builder .main-body .edit-task .setting-panel::-webkit-scrollbar-track, .course-builder .main-body .edit-task .form-body::-webkit-scrollbar-track, .course-builder .main-body .edit-lesson .question-panel::-webkit-scrollbar-track, .course-builder .main-body .edit-lesson .setting-panel::-webkit-scrollbar-track, .course-builder .main-body .edit-lesson .form-body::-webkit-scrollbar-track {
  background-color: var(--base-2);
}
.course-builder .main-body .edit-quiz .question-panel::-webkit-scrollbar-thumb, .course-builder .main-body .edit-quiz .setting-panel::-webkit-scrollbar-thumb, .course-builder .main-body .edit-quiz .form-body::-webkit-scrollbar-thumb, .course-builder .main-body .edit-task .question-panel::-webkit-scrollbar-thumb, .course-builder .main-body .edit-task .setting-panel::-webkit-scrollbar-thumb, .course-builder .main-body .edit-task .form-body::-webkit-scrollbar-thumb, .course-builder .main-body .edit-lesson .question-panel::-webkit-scrollbar-thumb, .course-builder .main-body .edit-lesson .setting-panel::-webkit-scrollbar-thumb, .course-builder .main-body .edit-lesson .form-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.course-builder .main-body .edit-quiz .question-list, .course-builder .main-body .edit-task .question-list, .course-builder .main-body .edit-lesson .question-list {
  display: grid;
  grid-template-columns: 1fr;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 24px;
  row-gap: 10px;
}

.course-preferences {
  background-color: var(--base-1);
  padding: 30px;
  height: calc(100vh - 54px);
  overflow: auto;
  padding-bottom: 0;
}
.course-preferences .preference-buttons {
  position: sticky;
  bottom: 0;
  background-color: var(--base-1);
  z-index: 5;
  padding: 20px 0 20px 0;
}
.course-preferences .display-header-group {
  display: flex;
  gap: 100px;
}
.course-preferences .tip {
  margin-top: 5px;
}
.course-preferences .container {
  max-width: 900px !important;
}
.course-preferences .course-image-field.image .ant-upload-list-item-container {
  width: 280px !important;
  height: 180px !important;
}
.course-preferences .field-description .fr-toolbar .fr-command.fr-btn svg {
  width: 18px;
}
.course-preferences .field-description .fr-toolbar .fr-command.fr-btn {
  height: 30px;
}
.course-preferences .body hr {
  display: none;
}
.course-preferences .body .field {
  background-color: white;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  padding: 16px;
}

.edit-question {
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
}
.edit-question.ready .modal-box {
  height: auto;
  max-height: calc(100vh - 40px);
  transform: translate(-50%, 0px);
  padding: 30px;
  opacity: 1;
  overflow: auto;
}
.edit-question .add-option {
  cursor: pointer;
  color: var(--secondary);
  font-weight: 400;
}
.edit-question .add-option:hover {
  filter: brightness(0.8);
}
.edit-question .modal-box {
  padding: 0 30px;
  width: 900px;
  height: 0;
  background-color: white;
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
  border-radius: 10px;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.26, 0.4, 0.42, 1.03);
}
.edit-question .modal-box .head .delete {
  position: absolute;
  top: 24px;
  right: 24px;
  transform: scale(1.2);
}
.edit-question .modal-box .body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-question .modal-box .body .response-type-icon {
  font-size: 3em !important;
}
.edit-question .response-type-option {
  display: block;
  width: 80%;
  margin: auto;
  padding: 30px;
  text-align: center;
}
.edit-question .response-type-option .option-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
.edit-question .response-type-option .option {
  cursor: pointer;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}
.edit-question .response-type-option .option p {
  font-size: 1.2em;
}
.edit-question .response-type-option .option i {
  font-size: 3em;
}
.edit-question .response-type-option .option:hover {
  border-color: var(--secondary);
  background-color: var(--base-1);
}
.edit-question .option-answer-item {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 10px;
}
.edit-question .option-answer-item.with-score .answer-box {
  grid-template-columns: 75px 90px auto 34px;
}
.edit-question .option-answer-item.with-score .answer-box[response-type="Yes/No"] {
  grid-template-columns: 75px 90px auto;
}
.edit-question .option-answer-item .answer-box {
  display: grid;
  grid-template-columns: 75px auto 34px;
  align-items: center;
  background-color: var(--base-1);
  padding: 5px 10px;
  gap: 10px;
  border-radius: 6px 6px 0 0;
}
.edit-question .option-answer-item .answer-box.task {
  grid-template-columns: auto 34px;
}
.edit-question .option-answer-item .answer-box[response-type="Yes/No"] {
  grid-template-columns: 75px auto;
}
.edit-question .option-answer-item .answer-box.mood-option {
  grid-template-columns: 30px auto;
}
.edit-question .option-answer-item .answer-box .mood-icon {
  width: 24px;
}
.edit-question .option-answer-item .answer-box.task[response-type="Yes/No"], .edit-question .option-answer-item .answer-box.task[response-type=YesNo] {
  grid-template-columns: auto;
}
.edit-question .option-answer-item .explanation-box {
  padding: 8px 10px;
}
.edit-question .option-answer-item .fa-trash {
  padding: 10px;
  border-radius: 5px;
}
.edit-question .option-answer-item .fa-trash:hover {
  background-color: var(--black-10);
  cursor: pointer;
}

.edit-quiz .question-list-item, .edit-task .question-list-item {
  width: 100%;
}
.edit-quiz .question-list-item .content, .edit-task .question-list-item .content {
  width: 100%;
}
.edit-quiz .question-list-item .content p, .edit-task .question-list-item .content p {
  width: 100%;
}
.edit-quiz .question-list-item .option i, .edit-task .question-list-item .option i {
  cursor: pointer;
}
.edit-quiz .question-list-item, .edit-task .question-list-item {
  position: relative;
  border: 1px solid var(--border-color);
  background: var(--base-1);
  border-radius: 6px;
  padding: 16px;
}
.edit-quiz .question-list-item .title-group, .edit-task .question-list-item .title-group {
  display: grid;
  grid-template-columns: 40px 1fr 65px;
  align-items: center;
  gap: 10px;
}
.edit-quiz .question-list-item .title-group i, .edit-task .question-list-item .title-group i {
  font-size: 1.5em;
  align-self: flex-start;
  margin-right: 10px;
}
.edit-quiz .question-list-item .title-group .truncate-3, .edit-task .question-list-item .title-group .truncate-3 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.edit-quiz .question-list-item:hover .option, .edit-task .question-list-item:hover .option {
  visibility: visible;
  opacity: 1;
}
.edit-quiz .question-list-item .option, .edit-task .question-list-item .option {
  height: 30px;
  position: absolute;
  right: 16px;
  top: 12px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.edit-quiz .question-list-item .option i, .edit-task .question-list-item .option i {
  padding: 8px;
  border-radius: 6px;
}
.edit-quiz .question-list-item .option i:hover, .edit-task .question-list-item .option i:hover {
  background-color: var(--black-10);
}

.edit-lesson .fr-wrapper .fr-element.fr-view {
  height: calc(100svh - 375px);
}

.edit-module .form-box {
  height: calc(100svh - 100px);
  overflow: auto;
  padding: 30px;
}
.edit-module .form-box .form {
  margin-top: 0 !important;
}

.import-list {
  height: calc(100svh - 55px);
  overflow: auto;
}

.import-content-box {
  height: calc(100svh - 55px);
  overflow: hidden;
  position: relative;
}
.import-content-box .delete {
  position: absolute;
  top: 10px;
  left: 410px;
}
.import-content-box .content-import-item {
  display: flex;
  justify-content: space-between;
}
.import-content-box .content-import-item:hover .import-button {
  opacity: 1;
}
.import-content-box .content-import-item .import-button {
  transition: 0.2s;
  opacity: 0;
}

.builder-loading {
  width: 0;
  height: 3px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: var(--primary);
}
.builder-loading.active {
  animation: is-loading 2s forwards;
}
.builder-loading.done {
  animation: is-done 0.4s forwards;
}

.course-image-field.logo2 .ant-upload-list-item {
  background-color: var(--black-80);
}

@keyframes is-loading {
  0% {
    width: 0;
  }
  100% {
    width: 100vw;
  }
}
@keyframes is-done {
  0% {
    width: 100vw;
    opacity: 1;
  }
  75% {
    width: 100vw;
    opacity: 1;
  }
  100% {
    width: 100vw;
    opacity: 0;
    visibility: hidden;
  }
}/*# sourceMappingURL=coursebuilder.css.map */