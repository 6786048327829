.course-builder
    background-color: var(--base-1)
    height: calc( 100vh - 55px )
    display: grid
    grid-template-columns: 400px auto

    .main-panel
        height: calc( 100vh - 55px )
        border-right: 1px solid var(--border-color)

        .header-box
            border-bottom: 1px solid var(--border-color)
            padding: 10px 15px
            background-color: white
            display: flex
            justify-content: space-between
            align-items: center
            height: 50px

            .button
                height: 32px
                font-weight: 400

            p
                letter-spacing: 1px
                font-weight: 500

        .card-content-box
            padding: 10px
            max-height: calc( 100% - 50px )

            overflow: auto

            &::-webkit-scrollbar
                width: 8px
            &::-webkit-scrollbar-track
                background-color: var(--base-1)

            &::-webkit-scrollbar-thumb
                background-color: darkgrey

            .card-content
                padding: 0
                border: 1px solid var(--border-color)
                border-radius: 10px
                overflow: hidden
                background-color: var(--base-1)
                // transition: all .3s

                &:hover
                    box-shadow: var(--box-shadow)
                    border-color: var(--secondary)

                    .header .option
                        opacity: 1

                .body
                    border-top: 1px solid var(--border-color)
                    padding: 0
                    min-height: 40px

                .header
                    padding: 16px
                    padding-right: 8px
                    background-color: white
                    display: grid
                    grid-template-columns: auto 30px
                    font-weight: 500
                    font-size: 1em

                    .option
                        padding: 5px 8px
                        border-radius: 5px
                        font-size: .9em
                        opacity: 0
                        cursor: pointer
                        transition: .3s

                    .option:hover
                        background-color: var(--black-10)

                .footer
                    display: flex
                    gap: 5px
                    align-items: center
                    justify-content: center
                    height: auto
                    padding: 10px
                    overflow: hidden
                    background-color: white

                    .button
                        font-weight: 400
                        height: 30px
                        padding: 5px 9px
                        font-size: .85em

                    .button.import i
                        font-size: 1.2rem
                        padding: 0
                        line-height: normal

        .card-content .content
            padding: 12px 10px
            // border-bottom: 1px solid var(--border-color)
            min-height: 60px
            position: relative
            // background-color: var(--base-1)

            &:not(:first-child)
                border-top: 1px solid var(--border-color)

            &:hover
                background-color: var(--base-1)

                .option
                    opacity: 1

            .title-box
                display: grid
                grid-template-columns: 24px auto 50px

                i
                    margin: auto

            .option
                position: absolute
                right: 10px
                top: 12px
                opacity: 0
                z-index: 2
                transition: .3s

                i
                    padding: 8px
                    font-size: .9em
                    cursor: pointer
                    border-radius: 4px

                    &:hover
                        background-color: var(--black-10)

    .main-body
        .empty-state
            display: flex
            justify-content: center
            flex-direction: column
            align-items: center
            height: 100%

            .tip
                width: 400px

            .title
                opacity: .5
                font-weight: 800

        .edit-module, .edit-lesson, .edit-task
            .header
                width: 100%
                padding: 0px 24px
                border-bottom: 1px solid var(--border-color)
                height: 50px

                .title
                    line-height: 53px

            .form-box
                width: 100%

                .form
                    padding: 24px
                    width: 600px
                    margin: auto
                    background-color: white
                    margin-top: 50px

                    .textarea
                        height: 150px

                    .flex-group
                        display: flex
                        justify-content: space-between

        .edit-lesson
            height: 100%

            .header, .header-breadcrumb
                display: flex
                align-items: center
                cursor: pointer
                width: 100%
                .input
                    width: 600px
                .title
                    font-size: 1.2em
                i
                    font-size: .9em

                .header-form
                    display: none

                .fa-pen
                    opacity: .5
                    padding: 10px 10px
                    border-radius: 5px
                    &:hover
                        opacity: 1
                        background-color: var(--black-10)

                &.edit
                    .header-form
                        display: flex

                    .header-breadcrumb
                        display: none
            .form-box
                height: 100%
                display: grid
                grid-template-columns: auto 300px

            .form-box .form
                width: 80%
                max-width: 1100px
                min-width: 560px
                // padding: 0

                &.lesson-form
                    // border: 1px solid var(--border-color)
                    border-radius: 10px
                    // background-color: var(--base-1)

            .form-box
                .panel-setting
                    height: 100%
                    border-left: 1px solid var(--border-color)

                    .ant-upload, .ant-upload .ant-btn
                        width: 100%

                    .add-attachment
                        width: 100%

                    .input-attachment
                        display: none

        .edit-quiz, .edit-task, .edit-lesson
            height: 100%
            .header
                width: 100%
                height: 50px
                border-bottom: 1px solid var(--border-color)
                display: flex
                justify-content: space-between
                align-items: center
                padding: 0 24px

                &.edit
                    .header-breadcrumb
                        display: none

                    .header-form
                        display: flex

                .header-breadcrumb
                    display: flex
                    height: 100%
                    align-items: center
                    cursor: pointer

                .title
                    font-size: 1.1em
                    margin: 0

                .fa-pen
                    font-size: .9em
                    padding: 10px
                    cursor: pointer
                    border-radius: 5px

                    &:hover
                        background-color: var(--black-10)

                .header-form
                    display: none

                    .input
                        width: 600px

            .form-box
                height: calc( 100vh - 105px )
                display: grid
                grid-template-columns: auto 300px

                .form-body
                    width: 100%
                    overflow: auto

            .setting-panel
                height: 100%
                overflow: auto
                border-left: 1px solid var(--border-color)

            .question-panel
                width: 100%
                overflow-y: auto
                overflow-x: hidden

                padding: 30px

                .button
                    width: 100%

                .question-panel-box
                    margin: auto
                    width: 600px

            .question-panel, .setting-panel, .form-body
                &::-webkit-scrollbar
                    width: 6px

                /* Track */
                &::-webkit-scrollbar-track
                    // box-shadow: inset 0 0 5px grey
                    // border-radius: 10px
                    background-color: var(--base-2)

                /* Handle */
                &::-webkit-scrollbar-thumb
                    background-color: rgba(0, 0, 0, .2)

            .question-list
                display: grid
                grid-template-columns: 1fr
                background-color: white
                border: 1px solid var(--border-color)
                border-radius: 6px
                padding: 16px
                margin-bottom: 24px
                row-gap: 10px

.course-preferences
    background-color: var(--base-1)
    padding: 30px
    height: calc( 100vh - 54px )
    overflow: auto
    padding-bottom: 0

    .preference-buttons
        position: sticky
        bottom: 0
        background-color: var(--base-1)
        z-index: 5
        padding: 20px 0 20px 0

    .display-header-group
        display: flex
        gap: 100px

    .tip
        margin-top: 5px
    .container
        max-width: 900px!important

    .course-image-field
        &.image
            .ant-upload-list-item-container
                width: 280px!important
                height: 180px!important

    .field-description
        .fr-toolbar .fr-command.fr-btn svg
            width: 18px
        .fr-toolbar .fr-command.fr-btn
            height: 30px
    .body
        hr
            display: none

        .field
            background-color: white
            border-radius: 6px
            border: 1px solid var(--border-color)
            padding: 16px

.edit-question
    position: fixed
    top: 0
    left: 0
    width: inherit
    height: inherit

    &.ready
        .modal-box
            height: auto
            max-height: calc( 100vh - 40px )
            transform: translate(-50%, 0px)
            padding: 30px
            opacity: 1
            overflow: auto

    .add-option
        cursor: pointer
        color: var(--secondary)
        font-weight: 400

        &:hover
            filter: brightness(.8)

    .modal-box
        padding: 0 30px
        width: 900px
        height: 0
        background-color: white
        position: absolute
        z-index: 9
        left: 50%
        top: 20px
        transform: translate(-50%, 0)
        border-radius: 10px
        overflow: hidden
        opacity: 0
        transition: .3s cubic-bezier(.26,.4,.42,1.03)

        .head
            .delete
                position: absolute
                top: 24px
                right: 24px
                transform: scale(1.2)
        .body
            display: flex
            justify-content: center
            align-items: center

            .response-type-icon
                font-size: 3em!important

    .response-type-option
        display: block
        width: 80%
        margin: auto
        padding: 30px
        text-align: center
        .option-group
            display: grid
            grid-template-columns: repeat(2, 1fr)
            gap: 24px

        .option
            cursor: pointer
            width: 100%
            padding: 30px
            border-radius: 10px
            border: 1px solid var(--border-color)
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            transition: .2s
            p
                font-size: 1.2em
            i
                font-size: 3em

            &:hover
                border-color: var(--secondary)
                background-color: var(--base-1)

    .option-answer-item
        border: 1px solid var(--border-color)
        border-radius: 5px
        margin-bottom: 10px

        &.with-score
            .answer-box
                grid-template-columns: 75px 90px auto 34px

                &[response-type="Yes/No"]
                    grid-template-columns: 75px 90px auto

        .answer-box
            display: grid
            grid-template-columns: 75px auto 34px
            align-items: center
            background-color: var(--base-1)
            padding: 5px 10px
            gap: 10px
            border-radius: 6px 6px 0 0
            // border-bottom: 1px solid var(--border-color)

            &.task
                grid-template-columns: auto 34px

            &[response-type="Yes/No"]
                grid-template-columns: 75px auto

            &.mood-option
                grid-template-columns: 30px auto

            .mood-icon
                width: 24px

        .answer-box.task
            &[response-type="Yes/No"], &[response-type="YesNo"]
                grid-template-columns: auto

        .explanation-box
            padding: 8px 10px

        .fa-trash
            padding: 10px
            border-radius: 5px

            &:hover
                background-color: var(--black-10)
                cursor: pointer

.edit-quiz, .edit-task
    .question-list-item
        width: 100%

        .content
            width: 100%

            p
                width: 100%

            // iframe
            //     width: 415px!important
            //     height: calc(415px * 9 / 16)!important
            //     aspect-ratio: 16 / 9

        .option i
            cursor: pointer

    .question-list-item
        position: relative
        border: 1px solid var(--border-color)
        background: var(--base-1)
        border-radius: 6px
        padding: 16px
        // cursor: dra

    .question-list-item  .title-group
        display: grid
        grid-template-columns: 40px 1fr 65px
        align-items: center
        gap: 10px

        i
            font-size: 1.5em
            align-self: flex-start
            margin-right: 10px

        .truncate-3
            width: 100%
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-line-clamp: 3
            -webkit-box-orient: vertical

    .question-list-item:hover
        .option
            visibility: visible
            opacity: 1

    .question-list-item .option
        height: 30px
        position: absolute
        right: 16px
        top: 12px
        visibility: hidden
        opacity: 0
        transition: .3s

        i
            padding: 8px
            border-radius: 6px

            &:hover
                background-color: var(--black-10)

.edit-lesson .fr-wrapper .fr-element.fr-view
    height: calc( 100svh - 375px )

.edit-module
    .form-box
        height: calc( 100svh - 100px )
        overflow: auto
        padding: 30px

        .form
            margin-top: 0!important

.import-list
    height: calc( 100svh - 55px )
    overflow: auto
.import-content-box
    height: calc( 100svh - 55px )
    overflow: hidden
    position: relative

    .delete
        position: absolute
        top: 10px
        left: 410px

    .content-import-item
        display: flex
        justify-content: space-between

        &:hover
            .import-button
                opacity: 1

        .import-button
            transition: .2s
            opacity: 0

.builder-loading
    width: 0
    height: 3px
    position: fixed
    z-index: 999
    top: 0
    left: 0
    background-color: var(--primary)

    &.active
        animation: is-loading 2s forwards

    &.done
        animation: is-done .4s forwards

.course-image-field.logo2
    .ant-upload-list-item
        background-color: var(--black-80)

@keyframes is-loading
    0%
        width: 0

    100%
        width: 100vw

@keyframes is-done
    0%
        width: 100vw
        opacity: 1
    75%
        width: 100vw
        opacity: 1
    100%
        width: 100vw
        opacity: 0
        visibility: hidden
