@import "../../styles/color"

.flex-space-between
    display: flex
    justify-content: space-between
    width: 100%

.profile
    background-image: linear-gradient(to right, var(--base-2), var(--base-2), var(--base-1), var(--base-1))
    height: calc( 100vh - 50px )
    overflow: hidden
    .ant-tabs
        .ant-tabs-nav
            padding-top: 16px
            height: calc( 100svh - 50px )

        .ant-tabs-tab
            margin-top: 5px!important
            padding-right: 35px!important
        .ant-tabs-tabpane
            padding: 0!important

    .profile-tabs
        height: 100%
        width: 100%
        border-right: $border

        .tab
            cursor: pointer
            width: 100%
            padding: 6px
            border-radius: 10px
            margin-bottom: 3px

            &.active
                background-color: #ff69000f
                color: $primary
                font-weight: 500

            &:hover
                background-color: #00000010

    .profile-box
        height: inherit
        background-color: $background-base
        overflow: auto
        margin-bottom: 30px

    .authored-courses, .authored-subjects, .personal-courses, .personal-subjects, .overview
        height: inherit
        position: relative
        opacity: 1
        padding: 0 10px

        &.is-loading
            pointer-events: none
            opacity: .5

        .header-box
            display: flex
            justify-content: space-between
            align-items: center
            // border-bottom: $border
            padding: 10px 16px 0 16px

            .title
                font-size: 16px

            .query
                display: flex
                gap: 8px

            .status
                .Dropdown-control
                    border: $border
                    border-radius: 5px
                    min-width: 100px
                    width: max-content
                    padding: 8px 30px 8px 10px
                    cursor: pointer

                .Dropdown-placeholder
                    &::before
                        content: "Status : "
                        opacity: .5

                .Dropdown-menu
                    border: $border
                    border-radius: 5px
                    margin-top: 3px

        table::-webkit-scrollbar
            width: 8px
        table::-webkit-scrollbar-track
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.068)

        table::-webkit-scrollbar-thumb
            background-color: darkgrey

    .authored-courses .header-box
        display: grid
        grid-template-columns: 1fr 1fr 120px
        gap: .5rem

        .query
            justify-content: flex-end

.data-body
    display: grid
    grid-template-columns: auto 0px
    height: calc( 100% - 54px )

    .table-box
        padding: 16px

    .detail-item-panel
        border-left: $border
        padding: 16px
        height: 100%

        .data-detail
            .label
                font-size: .95em
                opacity: .8
                margin-bottom: 0
                font-weight: 400

            .value
                margin-bottom: 15px
                font-size: 1em

            .image-preview img
                width: 100%
                height: 160px
                object-fit: cover
                border-radius: 10px

.authored-course-detail

    .header
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 15px

    .assignee, .assignee-head, .invited
        display: grid
        grid-template-columns: minmax(160px, 1.5fr) minmax(90px, 1fr) minmax(260px, 3.3fr) minmax(70px, .3fr)
        align-items: center

        .action
            display: flex
            justify-content: flex-end
            gap: 3px

        .extra
            display: grid
            grid-template-columns: minmax(180px, 2fr) minmax(80px, 1fr)

    .invited button
        padding: 0 .5rem

    .assignee-head
        font-weight: 500
        position: sticky
        top: 0
        background-color: white
        z-index: 2
        padding: .25rem 1rem

    .assignee, .invited
        border: 1px solid rgba(0, 0, 0, .05)
        border-radius: 10px
        margin-bottom: 8px
        background-color: white
        cursor: pointer
        transition: .2s
        padding: 12px 16px

        &:hover
            box-shadow: 0 5px 10px rgba(0, 0, 0, .1)
            border-color: $primary

// table style 
.authored-courses, .authored-subjects, .personal-courses, .personal-subjects
    .table-box
        height: calc( 100svh - 100px )

    .ant-table .ant-table-cell
        vertical-align: middle
        padding: 8px 10px

    .ant-table-row.ant-table-row-selected .ant-table-cell
        background: #f1ffee!important

    // .ant-table
    //     background: transparent
    // tr
    //     display: grid
    //     border-radius: 6px
    //     border: $border
    //     border-color: transparent
    //     overflow: hidden
    //     // margin-bottom: 5px

    // tbody tr:hover
    //     border-color: $secondary
    //     .action-group .button
    //         opacity: 1

    //     .elipsis
    //         opacity: 0
    // td, th
    //     padding: 10px!important

    //     &.action
    //         justify-content: flex-end
    //         text-align: right
    // td
    //     background-color: white
    //     display: flex
    //     align-items: center

    //     .action-group
    //         position: absolute
    //         right: 0
    //         display: flex
    //         gap: 0
    //         justify-content: space-between
    //         background: transparent

    //         .button
    //             transition: .3s
    //             opacity: 1

    //         .elipsis
    //             transition: .3s
    //             opacity: 1

    // .course-image
    //     object-fit: cover
    //     border-radius: 6px

// .authored-courses tr
//     grid-template-columns: minmax(400px, 80%) repeat(3, minmax(100px, 400px)) 80px

// .authored-subjects tr
//     grid-template-columns: minmax(400px, 80%) repeat(3, minmax(100px, 400px)) 80px

// .personal-subjects tr
//     grid-template-columns: minmax(400px, 80%) repeat(3, minmax(110px, 400px)) 80px

// .personal-courses tr
//     grid-template-columns: minmax(400px, 80%) repeat(2, minmax(110px, 400px)) 80px

.new-course-form
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 5
    background-color: var(--base-1)
    display: flex
    justify-content: center
    align-items: center

    .form-box
        width: 600px
        background-color: white
        padding: 30px
        border: $border
        border-radius: 8px
        .title
            text-align: center
            font-weight: 800

        .button-group
            display: grid
            grid-template-columns: repeat(2, 1fr)
            gap: 10px

.quiz-result-item
    display: grid
    grid-template-columns: 1fr 80px
    padding: 10px 0

    .score
        text-align: right

.authored-course-detail
    .button-review
        padding: 1px 6px
        font-size: .9em
        line-height: 20px
        height: auto

.review-tasks.has-review
    pointer-events: none
    opacity: .9

.review-tasks .question-list
    display: block

    .item
        border: 1px solid var(--border-color)
        margin-bottom: .75rem
        border-radius: .75rem
        transition: .25s
        cursor: pointer
        overflow: hidden

        &:hover
            border-color: var(--primary)
            box-shadow: 0 5px 10px rgba(0, 0, 0, .05)

        .header
            padding: 10px 16px
            background-color: var(--base-2)

    .label
        font-size: .9em
        font-weight: normal
        color: var(--text-secondary)

    .review-response
        border-top: 1px solid var(--border-color)

    .review-score .score-box
        display: flex
    .score-template
        display: flex

        span
            padding: 4px
            width: 40px
            text-align: center
            border: 1px solid var(--border-color)
            transition: .2s

            &:hover
                border-color: var(--secondary)
                border-right-color: var(--secondary)!important
                color: var(--secondary)

            &:not(:last-child)
                border-right-color: transparent

            &:first-child
                border-radius: 6px 0 0 6px

            &:last-child
                border-radius: 0 6px 6px 0

    .attachment-audio
        width: 100%

.review-task-title
    display: flex
    justify-content: space-between
    align-items: center

    .task-score-box
        display: flex
        align-items: center

    .label
        font-weight: normal

$mood-size: 40px
.review-mood
    .mood-score
        width: $mood-size
        height: $mood-size
        padding: 8px
        border: 1px solid var(--border-color)
        transition: .2s

        &:not(:last-child)
            border-right-color: transparent

        &:hover
            border-color: var(--secondary)

        &:first-child
            border-radius: 6px 0 0 6px
        &:last-child
            border-radius: 0 6px 6px 0

        &.active
            background-color: #fff7f1
            border-color: var(--primary)

.s-container
    display: grid
    max-width: 1650px
    margin: 0 auto

    .ant-tabs-content-holder
        background-color: var(--base-1)
        border-left: none

// MOBILE 
.is-mobile .profile
    .profile-tabs
        height: calc( 100svh - 50px )
        display: grid
        grid-template-columns: 1fr
        grid-template-rows: 1fr 50px
        grid-template-areas: "content" "tabs"

    .profile-tabs .ant-tabs-nav
        height: 100%
        padding: 0
        margin: 0
        grid-area: tabs

        .ant-tabs-nav-list
            display: flex
            justify-content: space-between
            align-items: center
            gap: 0
            width: 100%

        .ant-tabs-tab
            padding: 0!important
            margin: 0!important
            width: 100%
            text-align: center
            display: block

    .profile-tabs .ant-tabs-content
        height: 100%
        overflow: hidden

    // PERSONAL COURSES 

    .personal-courses, .authored-courses
        padding: 0

        .header-box
            display: grid
            grid-template-columns: 1fr
            padding: .75rem
            gap: .4rem
            border-bottom: 1px solid var(--border-color)

        .table-box
            height: auto
            padding: 0

            .ant-table-thead
                display: none

            .ant-pagination
                margin: .5rem 0
                justify-content: center

        .ant-table-tbody
            width: 100vw
            display: grid
            grid-template-columns: 1fr
            padding: .5rem
            gap: .75rem
            background-color: var(--base-2)

    .personal-courses .ant-table-row, .authored-courses .ant-table-row
        width: 100%
        display: grid
        grid-template-columns: repeat(5, 1fr)
        grid-template-areas: "title title title title status" "title title title title status" "action action action action action"
        border-radius: 10px
        background-color: var(--base-1)
        box-shadow: 0 5px 10px rgba(0, 0, 0, .05)

        .ant-table-cell
            width: 100%
            border: none

        .ant-table-cell:nth-child(4)
            display: none

        .ant-table-cell:nth-child(1)
            grid-area: title

            .ant-space
                display: grid
                grid-template-columns: 40px auto

        .ant-table-cell:nth-child(2)
            grid-area: status
            align-content: center

        .ant-table-cell:nth-child(3)
            grid-area: action

    // AUTHORED COURSES
    .authored-courses .ant-table-row
        grid-template-areas: "checkbox title title title action" "checkbox title title title action" "extra extra extra extra extra"
        grid-template-columns: 30px repeat(3, 1fr) 80px
        grid-template-rows: repeat(2, 30px) 40px

        .ant-table-cell:nth-child(1)
            grid-area: checkbox
            padding-right: 0

        .ant-table-cell:nth-child(2)
            grid-area: title

            .ant-space
                min-width: unset!important
                display: grid
                grid-template-columns: 40px auto
                .ant-space-item:nth-child(2)
                    width: 100%
                    word-wrap: break-word

        .ant-table-cell:nth-child(3)
            grid-area: extra

            .extra
                display: flex

        .ant-table-cell:nth-child(4)
            grid-area: action
            display: flex
            justify-content: flex-end

    .authored-courses .header-box
        display: grid
        grid-template-columns: repeat(2, 1fr) 120px
        gap: .5rem
        grid-template-areas: "title title button" "query query query"

        .query
            grid-area: query

        .title
            grid-area: title

        .button
            grid-area: button
            width: max-content

    .authored-courses .data-body
        height: calc( 100% - 95px )

.is-mobile .assign-course-form
    width: 100vw
    padding: .75rem
    .modal-box
        min-width: unset
        max-width: calc( 100vw - 1.5rem )

.is-mobile .authored-course-detail

    .ant-drawer-body
        padding: 1rem
    .assignee-head
        display: none

    .assignee, .invited
        width: 100%
        grid-template-columns: 4fr 1fr 30px
        grid-template-areas: "name date action" "extra extra extra"
        padding: 1rem .75rem
        box-shadow: 0 5px 10px rgba(0, 0, 0, .05)
    .name
        grid-area: name
    .date
        grid-area: date
    .extra
        grid-area: extra
        display: flex
        gap: .5rem
        width: 100%

        .status
            display: flex

        .result .is-link
            display: flex

    .action
        grid-area: action
