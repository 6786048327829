@import "../../styles/color"

.not-found
    display: flex
    align-items: center
    justify-content: center
    height: 100vh
    width: 100vw
    flex-direction: column

    h1
        font-size: 10em
        font-weight: 800
        line-height: 1em

    h2
        font-size: 3em
        font-weight: 500

    p
        font-size: 1.5em

    img
        width: 400px
