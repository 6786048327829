.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}
.not-found h1 {
  font-size: 10em;
  font-weight: 800;
  line-height: 1em;
}
.not-found h2 {
  font-size: 3em;
  font-weight: 500;
}
.not-found p {
  font-size: 1.5em;
}
.not-found img {
  width: 400px;
}/*# sourceMappingURL=error.css.map */