#froala-editor-field .fr-toolbar .fr-command.fr-btn svg.fr-svg, #froala-editor-field .fr-popup .fr-command.fr-btn svg.fr-svg, #froala-editor-field .fr-modal .fr-command.fr-btn svg.fr-svg {
  height: 18px;
}
#froala-editor-field .fr-toolbar .fr-command.fr-btn, #froala-editor-field .fr-popup .fr-command.fr-btn, #froala-editor-field .fr-modal .fr-command.fr-btn {
  height: 30px;
}
#froala-editor-field .fr-separator.fr-vs {
  height: 24px;
  width: 1px;
  margin: 8px 4px;
}
#froala-editor-field .fr-toolbar.fr-top {
  border-bottom: 1px solid var(--border-color);
  background-color: var(--base-2);
}
#froala-editor-field .fr-toolbar.fr-top .fr-newline {
  display: none;
}

.ant-table-wrapper .ant-table .ant-table-tbody .ant-table-cell,
.ant-table-wrapper .ant-table .ant-table-thead .ant-table-cell {
  padding: 7px 10px;
  font-size: 0.9em;
}

.ant-table-wrapper .ant-table .ant-table-thead {
  text-transform: capitalize;
}

.ant-table .ant-table-thead tr td, .ant-table .ant-table-thead tr th {
  background-color: var(--base-1);
}

.ant-tag {
  text-transform: capitalize;
}
.ant-tag.complete, .ant-tag.Completed, .ant-tag.Pass {
  color: var(--ant-green-7);
  background: var(--ant-green-1);
  border-color: var(--ant-green-3);
}
.ant-tag.Publish {
  color: var(--ant-cyan-7);
  background: var(--ant-cyan-1);
  border-color: var(--ant-cyan-3);
}
.ant-tag.awaiting.payment, .ant-tag.Enrolled {
  color: var(--ant-blue-7);
  background: var(--ant-blue-1);
  border-color: var(--ant-blue-3);
}
.ant-tag.Private, .ant-tag.Assigned {
  color: var(--ant-orange-7);
  background: var(--ant-orange-1);
  border-color: var(--ant-orange-3);
}
.ant-tag.Failed {
  color: var(--ant-red-7);
  background: var(--ant-red-1);
  border-color: var(--ant-red-3);
}

.fr-element .content .fr-video::after {
  display: none;
}/*# sourceMappingURL=custom.css.map */