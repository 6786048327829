.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.public-course-card {
  border-radius: 10px;
  border: 1px solid #d0d7de;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
}
.public-course-card:hover {
  box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.public-course-card:hover .card-head {
  transition: 0.3s;
  filter: brightness(0.7);
}
.public-course-card .card-head {
  height: 180px;
}
.public-course-card .card-head img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.public-course-card .card-body {
  height: 65px;
  padding: 10px;
  font-weight: 500;
  border-bottom: 1px solid #d0d7de;
}
.public-course-card .card-foot {
  padding: 10px;
  height: 65px;
  display: block;
}
.public-course-card .card-foot .author {
  display: flex;
  align-items: center;
}
.public-course-card .card-foot .author img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 24px;
  width: 24px;
  border-radius: 100%;
}
.public-course-card .card-foot .extra {
  display: flex;
  justify-content: space-between;
}

.is-mobile .public-course-card {
  box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.1);
}/*# sourceMappingURL=course.css.map */