.button:hover {
  filter: brightness(0.9);
}

.button.is-primary {
  background-color: #ff6900 !important;
  border: none;
  color: white;
}

.button.is-danger {
  background-color: #f76979 !important;
  border: none;
  color: white;
}

.button.is-outlined.is-danger:hover {
  color: white;
  background-color: #f76979 !important;
}

.button.is-secondary {
  background-color: #00a397 !important;
  color: white;
  font-weight: 500;
  border: none;
}
.button.is-secondary:hover {
  color: white;
}

.button.is-outlined.is-primary {
  color: #ff6900 !important;
  border-color: #ff6900 !important;
  background-color: #fafafd !important;
}

.button.is-outlined {
  border: 1px solid #d0d7de;
  background-color: #fafafd !important;
}

.button.is-outlined.is-secondary {
  border: 1px solid !important;
  color: #00a397 !important;
  border-color: #00a397 !important;
  background-color: #fafafd !important;
}
.button.is-outlined.is-secondary:hover {
  background-color: #00a397 !important;
  color: white !important;
  filter: brightness(1);
}

button.is-danger {
  background-color: #f76979 !important;
  color: white !important;
}

.navbar {
  padding: 5px 30px;
  background-color: white;
  border-bottom: 1px solid #d0d7de;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky !important;
  top: 0;
  height: 50px;
}
.navbar .nav-brand, .navbar .nav-center, .navbar .nav-end {
  display: flex;
  align-items: center;
}
.navbar .nav-end, .navbar .nav-brand {
  width: 25%;
}
.navbar .nav-center {
  width: 50%;
}
.navbar .nav-center .search, .navbar .nav-center .input {
  width: 100%;
  height: 35px;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.2s;
}
.navbar .nav-center .search:focus, .navbar .nav-center .input:focus {
  border-color: #00a397;
}
.navbar .nav-center .icon {
  height: 35px !important;
  background-color: #f6f8fa;
  border-radius: 0 10px 10px 0;
  border: 1px solid #d0d7de;
  cursor: pointer;
  pointer-events: all !important;
  color: #57606a !important;
}
.navbar .nav-center .icon:hover {
  filter: brightness(0.9);
}
.navbar .nav-end {
  justify-content: flex-end;
}
.navbar .nav-user {
  position: relative;
  display: flex;
  gap: 10px;
}
.navbar .nav-user .extra {
  display: flex;
}
.navbar .nav-user .extra .chat, .navbar .nav-user .extra .notif {
  padding: 5px 12px;
  cursor: pointer;
  border-radius: 5px;
}
.navbar .nav-user .extra .chat i, .navbar .nav-user .extra .notif i {
  font-size: 1.1em;
}
.navbar .nav-user .extra .chat:hover, .navbar .nav-user .extra .notif:hover {
  background-color: rgba(0, 0, 0, 0.062745098);
  color: #00a397;
}
.navbar .nav-user .user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.navbar .nav-user .user .avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.navbar .nav-user .user.active ~ .user-menu {
  display: block;
}
.navbar .nav-user .user.active ~ .close-popup {
  display: block;
}
.navbar .nav-user .close-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -99;
  display: none;
}
.navbar .nav-user .user-menu {
  display: none;
  padding: 10px 0;
  width: 200px;
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 2;
  background-color: white;
  border: 1px solid #d0d7de;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.062745098);
}
.navbar .nav-user .user-menu li {
  padding: 5px;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 5px;
}
.navbar .nav-user .user-menu li:hover {
  background-color: rgba(0, 0, 0, 0.062745098);
}
.navbar .nav-user .user-menu hr {
  margin: 10px;
  border: none;
  border-top: 1px solid #d0d7de;
  height: 1px;
}
.navbar .logo {
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
.navbar .button {
  height: 35px;
}

.navbar.nav-builder {
  height: -moz-max-content;
  height: max-content;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.navbar.nav-builder .nav-start {
  display: flex;
  align-items: center;
}
.navbar.nav-builder .nav-start img {
  width: 35px;
  height: 35px;
}
.navbar.nav-builder .nav-center {
  width: 100%;
  margin: 10px auto;
}
.navbar.nav-builder .nav-center .switch {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar.nav-builder .nav-center .switch .tab {
  padding: 5px;
  width: 150px;
  border: 1px solid #d0d7de;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  transition: 0.2s;
}
.navbar.nav-builder .nav-center .switch .tab.active {
  background-color: #00a397;
  color: white;
}
.navbar.nav-builder .nav-center .switch .tab.is-left {
  border-right: none;
  border-radius: 5px 0 0 5px;
}
.navbar.nav-builder .nav-center .switch .tab.is-right {
  border-left: none;
  border-radius: 0 5px 5px 0;
}
.navbar.nav-builder .nav-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.navbar.nav-builder .nav-end .preview {
  padding: 0 24px;
  font-weight: 500;
}
.navbar.nav-builder .nav-end a.close {
  width: 55px;
  font-size: 1.2em;
}
.navbar.nav-builder .nav-end a.close, .navbar.nav-builder .nav-end .preview {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #d0d7de;
}
.navbar.nav-builder .nav-end a.close:hover, .navbar.nav-builder .nav-end .preview:hover {
  background-color: #00a397;
  color: white;
}

.install-prompt {
  background-color: var(--primary);
  padding: 0.5rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.is-mobile .navbar {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8078431373);
  padding: 0.25rem 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.is-mobile .navbar .mobile-search {
  padding: 5px 12px;
}
.is-mobile .navbar .mobile-search .fa-search {
  font-size: 1.15rem;
}
.is-mobile .navbar .nav-brand {
  width: 100%;
}
.is-mobile .navbar .nav-end {
  width: 100%;
}

.is-mobile .navbar-search-panel {
  position: fixed;
  width: 100vw;
  height: 100svh;
  z-index: 50;
  padding: 1rem;
  top: 0;
  left: 0;
  isolation: isolate;
}
.is-mobile .navbar-search-panel .overlay {
  position: absolute;
  width: 100vw;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.3137254902);
  z-index: -1;
  top: 0;
  left: 0;
}/*# sourceMappingURL=navbar.css.map */