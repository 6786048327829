#review-course
    .logo-holder
        padding: 1.5rem
        display: flex
        align-items: center
        justify-content: center
        img
            width: 300px

    .review-form
        margin: auto
        width: clamp(500px, 50%, 800px)
