@import "../../styles/color"

.classroom
    width: 100vw

    .no-border
        border: none!important

    .tab-switch
        cursor: pointer
        position: absolute
        bottom: 30px
        left: 185px
        padding: 5px 10px
        border: $border
        border-radius: 6px
        background-color: white
        transition: .3s

    &.collapse
        .tab-switch
            left: 40px

        .body
            grid-template-columns: 60px auto
            .ant-tabs-tab .tab-item
                grid-template-columns: 30px 0
                overflow: hidden

    .head
        display: flex
        height: 50px
        align-items: center
        border-bottom: $border
        padding: 24px

        .back
            margin-right: 1em
            padding: 5px 10px
            cursor: pointer
            background-color: $base
            border-radius: 6px

    .body
        display: grid
        grid-template-columns: 200px auto
        height: calc( 100vh - 100px )
        background-color: $base
        transition: .3s

        .ant-tabs-tab
            margin: 0!important
            padding: 8px 20px

            .tab-item
                display: grid
                grid-template-columns: 30px auto

                .icon
                    line-height: 30px
        .ant-tabs-content-holder
            padding: 0

        .ant-tabs-tabpane.ant-tabs-tabpane-active
            padding: 0!important

    .classroom-member
        .has-avatar
            display: flex
            align-items: center
        .member-avatar
            height: 24px
            width: 24px
            object-fit: cover
            border-radius: 20px

    .classroom-preferences
        width: 50%
        min-width: 600px
        max-width: 1000px
        background-color: white
        border: $border
        border-radius: 10px
        padding: 24px
        margin: 24px auto
        margin-bottom: 50px

        .field-description
            .fr-toolbar .fr-command.fr-btn svg
                width: 18px
            .fr-toolbar .fr-command.fr-btn
                height: 30px

    .ant-tabs-content-holder
        height: calc( 100vh - 100px )
        overflow: auto

    .classroom-tasks
        .task-quiz, .task-lesson
            .header
                display: flex
                align-items: center
                height: 40px
                border-bottom: $border

            .back
                border-radius: 6px
                padding: 5px 10px
                background-color: $base
                cursor: pointer
                transition: .3s
                &:hover
                    background-color: $transparent

            .body
                display: grid
                grid-template-columns: auto 300px
                height: calc( 100vh - 140px )

                .setting-panel
                    border-left: $border

        .task-lesson
            .editor-box
                padding: 24px
                background-color: white
                width: 1100px
                margin: auto

                .fr-wrapper
                    height: calc(100vh - 360px)!important

        .task-quiz
            .question-box
                margin: 30px auto
                width: 900px
                .question-list
                    padding: 24px
                    border-radius: 10px
                    border: $border
                    background-color: white
                    width: 100%

                .question-list-item
                    position: relative
                    border: 1px solid #d0d7de
                    background: #fafafd
                    border-radius: 6px
                    padding: 12px
                    margin-bottom: 10px

                    .title-group
                        display: grid
                        grid-template-columns: 24px auto 40px

                    .option
                        height: 30px
                        position: absolute
                        right: 16px
                        top: calc(50% - 15px)
                        visibility: hidden
                        opacity: 0
                        transition: 0.3s
                        width: 50px
                        display: flex
                        justify-content: space-between
                        align-items: center
                        cursor: pointer

                        i
                            padding: 8px
                            border-radius: 4px

                        i:hover
                            background-color: $transparent

                    &:hover
                        .option
                            visibility: visible
                            opacity: 1

    .classroom-programs
        .program
            .ant-tabs-content-holder
                height: calc( 100vh - 150px )
                overflow: auto

            .header
                display: flex
                align-items: center
                border-bottom: $border
                .back
                    cursor: pointer
                    padding: 5px 10px
                    border-radius: 6px
                    &:hover
                        background-color: $transparent

            .body
                display: grid
                grid-template-columns: auto 300px
                height: calc(100vh - 150px)
                .ant-tabs
                    display: grid
                    grid-template-columns: 180px auto
                    background-color: #fafafd

                    .ant-tabs-tab .tab-item
                        grid-template-columns: 30px auto

                .setting
                    height: calc(100vh - 150px)
                    border-left: $border

        .add-program
            position: fixed
            top: 0
            left: 0
            z-index: 99
            width: 100vw
            height: 100vh
            .overlay
                width: 100%
                height: 100%
                background-color: rgba(0, 0, 0, .7)

            .modal-box
                position: absolute
                top: 30px
                left: 50%
                transform: translateX(-50%)
                width: 500px
                background-color: white
                border-radius: 6px

            .modal-header
                border-bottom: $border
                display: flex
                align-items: center

        .form-add-courses
            width: 100vw
            height: 100vh
            position: fixed
            top: 0
            left: 0
            z-index: 99

            .overlay
                width: 100%
                height: 100%
                background-color: rgba(0, 0, 0, .7)

            .modal-box
                background-color: white
                border-radius: 8px
                position: absolute
                top: 30px
                left: 50%
                width: 900px
                transform: translateX(-50%)

            .modal-header
                border-bottom: $border
                background-color: $base
                border-radius: 8px 8px 0 0

            .course-item
                display: grid
                grid-template-columns: 1fr 100px repeat(4, minmax(40px, 60px))

    .classroom-member, .classroom-preferences, .classroom-modules, .classroom-tasks
        .ant-table-cell
            padding: 10px 16px

            &.action
                text-align: right
            .tip
                font-size: .85em
                opacity: .7
