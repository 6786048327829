@import "../../styles/color"

.footer-box
    margin-top: 30px
    background-color: #fafafa
    padding: 24px
    color: $icon

    .container
        display: grid
        grid-template-columns: 1fr 2fr 1fr!important
        align-items: center

    .end
        display: flex
        justify-content: flex-end
        gap: 30px

    .logo-box
        display: flex
        justify-content: center
        align-items: center

        svg
            width: 30px
            fill: $icon

.is-mobile .footer-box
    padding: 3rem 1rem

    .container
        display: grid
        grid-template-columns: 1fr!important
        grid-template-areas: "logo" "social" "copyright"
        justify-content: center
        gap: 1rem

    .end
        grid-area: social
        justify-content: center

    .logo-box
        grid-area: logo
        margin-bottom: 1.5rem

        svg
            width: 50px

    .start
        grid-area: copyright
        text-align: center
