._notification {
  font-size: 1em;
}
._notification .notification-icon {
  position: relative;
}
._notification .notif-count {
  position: absolute;
  top: -10px;
  right: -18px;
  background-color: var(--primary);
  border-radius: 20px;
  padding: 3px 8px;
  font-size: 0.75em;
  color: white;
  border: 1px solid white;
}

.notification-modal .notif-item {
  margin-bottom: 6px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}
.notification-modal .notif-item .group {
  display: flex;
  justify-content: space-between;
}
.notification-modal .notif-item .is-link {
  opacity: 0;
  transition: 0.2s;
  font-size: 0.85em;
  color: var(--primary);
  height: 0;
  overflow: hidden;
}
.notification-modal .notif-item:hover .is-link {
  height: auto;
  opacity: 1;
  transition: 0.2s;
}/*# sourceMappingURL=notification.css.map */